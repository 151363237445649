import {createContext, useContext, useReducer, useMemo} from 'react';
import PropTypes from 'prop-types';
import * as Utils from '../shared/utils';
const MaterialUI = createContext();
MaterialUI.displayName = 'MaterialUIContext';

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case 'MINI_SIDENAV': {
      return {...state, miniSidenav: action.value};
    }
    case 'TRANSPARENT_SIDENAV': {
      return {...state, transparentSidenav: action.value};
    }
    case 'WHITE_SIDENAV': {
      return {...state, whiteSidenav: action.value};
    }
    case 'SIDENAV_COLOR': {
      return {...state, sidenavColor: action.value};
    }
    case 'TRANSPARENT_NAVBAR': {
      return {...state, transparentNavbar: action.value};
    }
    case 'FIXED_NAVBAR': {
      return {...state, fixedNavbar: action.value};
    }
    case 'OPEN_CONFIGURATOR': {
      return {...state, openConfigurator: action.value};
    }
    case 'DIRECTION': {
      return {...state, direction: action.value};
    }
    case 'LAYOUT': {
      return {...state, layout: action.value};
    }
    case 'DARKMODE': {
      return {...state, darkMode: action.value};
    }
    case 'ORDER_DATA': {
      return {...state, orderData: action.value};
    }
    case 'ORDER_QUERY_DATE': {
      return {...state, orderQueryDate: action.value};
    }
    case 'STORE_INFO': {
      return {...state, storeInfo: action.value};
    }
    case 'ROOM_EXCEL': {
      return {...state, roomExcelData: action.value};
    }
    case 'QUERY_DATE_ROOM': {
      return {...state, queryDateRoom: action.value};
    }
    case 'ROOM_SERVICE_ORDER_DATA': {
      return {...state, roomServiceOrderData: action.value};
    }
    case 'CONCIERGE_ORDER_DATA': {
      return {...state, conciergeOrderData: action.value};
    }
    case 'HEADER_BUTTON_CMD': {
      return {...state, headerNavbarButtonCmd: action.value};
    }
    case 'ROOM_ZONE_INFO': {
      return {...state, roomServiceZoneInfo: action.value};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({children}) {
  const initialState = {
    miniSidenav: true,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: 'info',
    transparentNavbar: true,
    fixedNavbar: false,
    openConfigurator: false,
    direction: 'ltr',
    layout: 'dashboard',
    darkMode: false,
    orderData: {
      orderCount: 0,
      orderPay: 0,
    },
    storeInfo: {},
    roomExcelData: [],
    orderQueryDate: {
      stDate: Utils.stMonthDay(),
      edDate: new Date(),
    },
    queryDateRoom: new Date(),
    roomServiceOrderData: {
      tOrderCount: 0,
      tOrderPrice: 0,
      tOrderStorePrice: 0,
      tOrderDeliveryPrice: 0,
      tRefundCount: 0,
      tRefundPrice: 0,
    },
    roomServiceZoneInfo: {
      zoneEmergency: 'N',
      zoneBanner: 'N',
    },
    conciergeOrderData: {
      tTotalPoid: 0,
      tTotalOrderPrice: 0,
    },
    headerNavbarButtonCmd: '',
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error('useMaterialUIController should be used inside the MaterialUIControllerProvider.');
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({type: 'MINI_SIDENAV', value});
const setTransparentSidenav = (dispatch, value) => dispatch({type: 'TRANSPARENT_SIDENAV', value});
const setWhiteSidenav = (dispatch, value) => dispatch({type: 'WHITE_SIDENAV', value});
const setSidenavColor = (dispatch, value) => dispatch({type: 'SIDENAV_COLOR', value});
const setTransparentNavbar = (dispatch, value) => dispatch({type: 'TRANSPARENT_NAVBAR', value});
const setFixedNavbar = (dispatch, value) => dispatch({type: 'FIXED_NAVBAR', value});
const setOpenConfigurator = (dispatch, value) => dispatch({type: 'OPEN_CONFIGURATOR', value});
const setDirection = (dispatch, value) => dispatch({type: 'DIRECTION', value});
const setLayout = (dispatch, value) => dispatch({type: 'LAYOUT', value});
const setDarkMode = (dispatch, value) => dispatch({type: 'DARKMODE', value});
const setOrderData = (dispatch, value) => dispatch({type: 'ORDER_DATA', value});
const setOrderQueryDate = (dispatch, value) => dispatch({type: 'ORDER_QUERY_DATE', value});
const setStoreInfo = (dispatch, value) => dispatch({type: 'STORE_INFO', value});
const setRoomExcelData = (dispatch, value) => dispatch({type: 'ROOM_EXCEL', value});
const setRoomZoneInfo = (dispatch, value) => dispatch({type: 'ROOM_ZONE_INFO', value});

const setQueryDateRoom = (dispatch, value) => dispatch({type: 'QUERY_DATE_ROOM', value});
const setRoomServiceOrderData = (dispatch, value) => dispatch({type: 'ROOM_SERVICE_ORDER_DATA', value});
const setConciergeOrderData = (dispatch, value) => dispatch({type: 'CONCIERGE_ORDER_DATA', value});
const setHeaderNavbarButtonCmd = (dispatch, value) => dispatch({type: 'HEADER_BUTTON_CMD', value});

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setOrderData,
  setOrderQueryDate,
  setStoreInfo,
  setRoomExcelData,
  setRoomZoneInfo,
  setQueryDateRoom,
  setRoomServiceOrderData,
  setConciergeOrderData,
  setHeaderNavbarButtonCmd,
};
