import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ko';
import {navbar, navbarContainer, navbarRow, navbarMobileMenu, navbarFoldMenu} from './styles';
import {useMaterialUIController, setTransparentNavbar, setMiniSidenav, setOrderQueryDate, setQueryDateRoom, setHeaderNavbarButtonCmd, setRoomZoneInfo} from 'context';
import * as Utils from '../../../shared/utils';
import '../../../assets/common.css';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as Constants from '../../../shared/Constants';
import MenuItem from '@mui/material/MenuItem';
import {isNumber} from 'chart.js/helpers';
import {CSVLink, CSVDownload} from 'react-csv';

function HeaderNavbar({absolute, light, isMini, title, mode}) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {miniSidenav, transparentNavbar, fixedNavbar, darkMode, storeInfo, queryDateRoom, roomServiceOrderData, roomServiceZoneInfo, roomExcelData} = controller;
  const [queryDateOfRoom, setQueryDateOfRoom] = useState(queryDateRoom);
  const [roomTodayDate, setRoomTodayDate] = useState('');
  const [roomTodayOrderCount, setRoomTodayOrderCount] = useState(0);
  const [roomTodayOrderPrice, setRoomTodayOrderPrice] = useState(0);
  const [roomTodayRefundCount, setRoomTodayRefundCount] = useState(0);
  const [roomTodayRefundPrice, setRoomTodayRefundPrice] = useState(0);
  const [autoRefesh, setAutoRefesh] = useState(false);
  const [autoRefeshCount, setAutoRefeshCount] = useState(9);
  const [intervalTimer, setIntervalTimer] = useState(null);
  const fileName = 'vooroom-roomservice_' + Utils.getNowTimeType2() + '.csv';

  // 10초 자동 업데이트 동작
  useEffect(() => {
    if (autoRefesh) {
      // 타이머 ON
      console.log('타이머 온');
      const timerId = setInterval(() => {
        setAutoRefeshCount(previousCount => {
          return previousCount > 0 ? previousCount - 1 : 9;
        });
      }, 1000);
      setIntervalTimer(timerId);
    } else {
      // 타이머 OFF
      console.log('타이머 오프');
      if (intervalTimer) {
        setAutoRefeshCount(9);
        clearInterval(intervalTimer);
        setIntervalTimer(null);
      }
    }
  }, [autoRefesh]);

  useEffect(() => {
    if (autoRefeshCount === 0) {
      console.log('주문 새로 고침!!!');
      setHeaderNavbarButtonCmd(dispatch, 'room_refesh');
    }
  }, [autoRefeshCount]);

  useEffect(() => {
    setQueryDateRoom(dispatch, queryDateOfRoom);
  }, [queryDateOfRoom]);

  useEffect(() => {
    setRoomTodayOrderCount(roomServiceOrderData.tOrderCount);
    setRoomTodayOrderPrice(roomServiceOrderData.tOrderPrice);

    setRoomTodayRefundCount(roomServiceOrderData.tRefundCount);
    setRoomTodayRefundPrice(roomServiceOrderData.tRefundPrice);
  }, [roomServiceOrderData]);

  useEffect(() => {
    setRoomTodayDate(Utils.getOrderDateStrType2(queryDateRoom) + ' (' + Utils.getDayText(new Date(queryDateRoom)) + ')');
    setQueryDateOfRoom(queryDateRoom);
  }, [queryDateRoom]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    const handleTransparentNavbar = () => {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    };

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  // Styles for the navbar icons
  const iconsStyle = ({palette: {dark, white, text}, functions: {rgba}}) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={theme => navbar(theme, {transparentNavbar, absolute, light, darkMode})}
      style={{minHeight: '76px', maxHeight: '76px'}}>
      <Toolbar sx={theme => navbarContainer(theme)} style={{justifyContent: 'flex-start'}}>
        {/*{!isMini && (
          <MDBox>
            <MDBox sx={theme => navbarRow(theme, {isMini})}>
              <MDTypography sx={navbarMobileMenu}>{storeInfo.store_name}</MDTypography>
            </MDBox>

            <MDBox color={light ? 'white' : 'inherit'} sx={navbarFoldMenu}>
              <IconButton size="small" disableRipple color="inherit" onClick={handleMiniSidenav}>
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
            </MDBox>
          </MDBox>
        )}*/}
        <div className="dashboard-bar">
          <div style={{display: 'flex', alignItems: 'center', minWidth: '220px'}}>
            <MDBox color={light ? 'white' : 'inherit'}>
              <IconButton size="small" disableRipple color="inherit" onClick={handleMiniSidenav}>
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
            </MDBox>
            <MDTypography
              variant="caption"
              color="text"
              fontWeight="medium"
              fontSize="medium"
              style={{
                textAlign: 'center',
                color: '#222',
                marginLeft: '6px',
                marginRight: '16px',
              }}>
              {title}
            </MDTypography>
          </div>
          {mode === 'room' && (
            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
              <MDTypography variant="caption" color="dark" fontWeight="medium" className="flex-center" style={{marginRight: '24px'}}>
                <Icon fontSize="small" style={{marginRight: '4px'}}>
                  date_range
                </Icon>
                {roomTodayDate}
              </MDTypography>
              <MDTypography variant="caption" color="dark" fontWeight="medium" className="flex-center" style={{marginRight: '24px'}}>
                <Icon fontSize="small" style={{marginRight: '4px'}}>
                  add_shopping_cart
                </Icon>
                주문 {roomTodayOrderCount} 건 (POID)
              </MDTypography>
              <MDTypography variant="caption" color="dark" fontWeight="medium" className="flex-center" style={{marginRight: '24px'}}>
                <Icon fontSize="small" style={{marginRight: '4px'}}>
                  local_atm
                </Icon>
                총 매출 {roomTodayOrderPrice && Utils.wonPriceStr(roomTodayOrderPrice)} 원
              </MDTypography>
              <MDTypography variant="caption" color="text" fontWeight="medium" className="flex-center" style={{marginRight: '24px'}}>
                <Icon fontSize="small" style={{marginRight: '4px'}}>
                  local_atm
                </Icon>
                스토어 {roomServiceOrderData.tOrderStorePrice && Utils.wonPriceStr(roomServiceOrderData.tOrderStorePrice)} 원
              </MDTypography>
              <MDTypography variant="caption" color="text" fontWeight="medium" className="flex-center">
                <Icon fontSize="small" style={{marginRight: '4px'}}>
                  local_atm
                </Icon>
                배달비 {roomServiceOrderData.tOrderDeliveryPrice && Utils.wonPriceStr(roomServiceOrderData.tOrderDeliveryPrice)} 원
              </MDTypography>
              <MDTypography variant="caption" color="dark" fontWeight="medium" style={{marginLeft: '22px', marginRight: '22px'}}>
                |
              </MDTypography>
              <MDTypography variant="caption" color="text" fontWeight="medium" className="flex-center" style={{marginRight: '24px'}}>
                <Icon fontSize="small" style={{marginRight: '4px'}}>
                  restore
                </Icon>
                환불 {roomTodayRefundCount} 건 (BOID)
              </MDTypography>
              <MDTypography variant="caption" color="text" fontWeight="medium" className="flex-center" style={{marginRight: '22px'}}>
                <Icon fontSize="small" style={{marginRight: '4px'}}>
                  local_atm
                </Icon>
                환불 {roomTodayRefundPrice && Utils.wonPriceStr(roomTodayRefundPrice)} 원
              </MDTypography>
              {autoRefesh ? (
                <MDTypography variant="caption" color="error" fontWeight="medium" className="flex-center" style={{marginRight: '22px'}}>
                  <Icon fontSize="small" style={{marginRight: '4px'}}>
                    access_time
                  </Icon>
                  {autoRefeshCount}초 후 업데이트
                </MDTypography>
              ) : (
                <MDTypography variant="caption" color="error" fontWeight="medium" className="flex-center"></MDTypography>
              )}
              <MDTypography variant="caption" color="dark" fontWeight="medium" style={{marginLeft: '0px', marginRight: '32px'}}>
                |
              </MDTypography>
              {/*<Button
                onClick={() => {
                  !autoRefesh && setAutoRefesh(true);
                  setHeaderNavbarButtonCmd(dispatch, 'room_play');
                }}>
                PLAY
              </Button>
              <Button
                style={{marginRight: '16px'}}
                onClick={() => {
                  autoRefesh && setAutoRefesh(false);
                  setHeaderNavbarButtonCmd(dispatch, 'room_stop');
                }}>
                STOP
              </Button>*/}

              <CSVLink filename={fileName} data={roomExcelData}>
                <Button variant="outlined" color="secondary" sx={{backgroundColor: '#f0f2f5', borderColor: '#d2d6da', color: '#495057', width: 102, height: 44.13, marginTop: 0.5}}>
                  EXCEL
                </Button>
              </CSVLink>

              <Button
                variant="outlined"
                color="secondary"
                sx={{backgroundColor: '#f0f2f5', borderColor: '#d2d6da', color: '#495057', width: 102, height: 44.13, marginTop: 0.5, marginLeft: '16px'}}
                onClick={() => {
                  setHeaderNavbarButtonCmd(dispatch, 'room_refesh');
                }}>
                새로고침
              </Button>

              <div style={{marginLeft: '16px'}}>
                <FormControl sx={{padding: 0, margin: 0, border: 0, paddingTop: 0.5}} size="small">
                  <Select
                    labelId="demo-select-small"
                    value={roomServiceZoneInfo.zoneEmergency}
                    sx={{
                      fontSize: '20px',
                      fontWeight: '600',
                      height: '44.13px',
                      width: '102px',
                      textAlign: 'center',
                      backgroundColor: '#f0f2f5',
                    }}
                    onChange={e => {
                      const value = e.target.value;
                      console.log(value);

                      const mInfo = roomServiceZoneInfo;
                      mInfo.zoneEmergency = value;
                      setRoomZoneInfo(dispatch, mInfo);
                      if (value === 'N') {
                        setHeaderNavbarButtonCmd(dispatch, 'room_emergency_off');
                      } else {
                        setHeaderNavbarButtonCmd(dispatch, value);
                      }
                    }}>
                    <MenuItem value={'N'}>상황 OFF</MenuItem>
                    <MenuItem value={'Y'}>이멀전시(Y)</MenuItem>
                    <MenuItem value={'J'}>기상지연(J)</MenuItem>
                    <MenuItem value={'F'}>주문폭주(F)</MenuItem>
                    <MenuItem value={'M'}>임시휴무(M)</MenuItem>
                    <MenuItem value={'W'}>임시휴무(W)</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div style={{marginLeft: '16px'}}>
                <FormControl sx={{padding: 0, margin: 0, border: 0, paddingTop: 0.5}} size="small">
                  <Select
                    labelId="demo-select-small"
                    value={roomServiceZoneInfo.zoneBanner}
                    sx={{
                      fontSize: '20px',
                      fontWeight: '600',
                      height: '44.13px',
                      width: '102px',
                      textAlign: 'center',
                      backgroundColor: '#f0f2f5',
                    }}
                    onChange={e => {
                      const value = e.target.value;
                      console.log(value);
                      const mInfo = roomServiceZoneInfo;
                      mInfo.zoneBanner = value;
                      setRoomZoneInfo(dispatch, mInfo);

                      if (value === 'N') {
                        setHeaderNavbarButtonCmd(dispatch, 'room_banner_off');
                      } else {
                        setHeaderNavbarButtonCmd(dispatch, value);
                      }
                    }}>
                    <MenuItem value={'N'}>배너 OFF</MenuItem>
                    <MenuItem value={'H'}>배달지연(H)</MenuItem>
                    <MenuItem value={'Z'}>기상지연(Z)</MenuItem>
                    <MenuItem value={'R'}>강우지연(R)</MenuItem>
                    <MenuItem value={'S'}>강설지연(S)</MenuItem>
                    <MenuItem value={'DR'}>강우중단(DR)</MenuItem>
                    <MenuItem value={'DS'}>강설중단(DS)</MenuItem>
                    <MenuItem value={'D'}>내부사정휴무(D)</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div style={{minWidth: '150px', maxWidth: '150px', paddingTop: '4px', marginLeft: '12px'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                  <DatePicker
                    label="조회일"
                    inputFormat="YYYY-MM-DD"
                    value={queryDateOfRoom}
                    onChange={newValue => {
                      setQueryDateOfRoom(newValue);
                    }}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
HeaderNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  title: '',
  mode: '',
};

// Typechecking props for the DashboardNavbar
HeaderNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  title: PropTypes.string,
  mode: PropTypes.string,
};

export default HeaderNavbar;
