/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
// import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
// import Notifications from "layouts/notifications";
// import Profile from "layouts/profile";
// import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";

import Main from 'layouts/office/main';
import RoomService from 'layouts/office/roomService';
import Product from 'layouts/office/product';
import Store from 'layouts/office/store';
import Concierge from 'layouts/office/concierge';
import Customer from 'layouts/office/customer';

// @mui icons
import Icon from '@mui/material/Icon';

const routes = [
  {
    type: 'collapse',
    name: '대시보드',
    key: 'main',
    icon: <Icon fontSize="small">grid_view</Icon>,
    route: '/main',
    component: <Main />,
  },
  {
    type: 'divider',
    name: 'DIV1',
    key: 'div1',
  },
  {
    type: 'collapse',
    name: '룸서비스',
    key: 'room',
    icon: <Icon fontSize="small">room_service</Icon>,
    route: '/room',
    component: <RoomService />,
  },
  {
    type: 'collapse',
    name: '상품관리',
    key: 'product',
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: '/product',
    component: <Product />,
  },
  {
    type: 'collapse',
    name: '매장관리',
    key: 'store',
    icon: <Icon fontSize="small">store</Icon>,
    route: '/store',
    component: <Store />,
  },
  {
    type: 'divider',
    name: 'DIV2',
    key: 'div2',
  },
  {
    type: 'collapse',
    name: '컨시어지',
    key: 'concierge',
    icon: <Icon fontSize="small">dry_cleaning</Icon>,
    route: '/concierge',
    component: <Concierge />,
  },
  {
    type: 'divider',
    name: 'DIV3',
    key: 'div3',
  },
  {
    type: 'collapse',
    name: '고객관리',
    key: 'customer',
    icon: <Icon fontSize="medium">person</Icon>,
    route: '/customer',
    component: <Customer />,
  },
  {
    type: 'divider',
    name: 'DIV4',
    key: 'div4',
  },
  {
    type: 'collapse',
    name: '로그아웃',
    key: 'log-out',
    icon: <Icon fontSize="small">logout</Icon>,
    route: '/logout',
  },
];

export default routes;
