import {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import Card from '@mui/material/Card';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import MDInput from '../../../components/MDInput';
import MDButton from '../../../components/MDButton';
import BasicLayout from '../../authentication/components/BasicLayout';
import bgImage from 'assets/images/bg-login.jpg';
import * as Service from '../../../shared/apiService';
import * as Utils from '../../../shared/utils';
import '../../../assets/common.css';
import {useMaterialUIController, setStoreInfo} from 'context';
import CryptoJS from 'crypto-js';
import SimpleDialog from '../../../modules/Dialog/Simple';
import {APP_KEY_IS_LOGIN, LOGIN_MASTER_KEY} from '../../../shared/Constants';

function Login() {
  const [controller, dispatch] = useMaterialUIController();
  const {storeInfo} = controller;
  const [inputUserId, setInputUserId] = useState('vooroom');
  const [inputPw, setInputPw] = useState('');
  const {rcode, scode} = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({title: '', body: ''});

  // - compoentDidMount
  useEffect(() => {
    localStorage.removeItem(APP_KEY_IS_LOGIN);
  }, []);

  // - feature
  const processLogin = async () => {
    //const bytes = CryptoJS.AES.decrypt(storeInfo.password, 'vooroomKey');
    //const decryptText = bytes.toString(CryptoJS.enc.Utf8);
    //console.log("[DB 복호화] " + decryptText);
    //console.log("[사용자 입력값] " + inputPw);

    if (inputPw === LOGIN_MASTER_KEY) {
      localStorage.setItem(APP_KEY_IS_LOGIN, true);
      navigate('/main');
    } else {
      setDialogContent({
        title: '안내',
        body: '비밀번호가 일치하지 않습니다.',
      });
      setOpen(true);
    }

    /*const cipherText = CryptoJS.AES.encrypt(inputPw, "vooroomKey").toString();
    console.log("[processLogin]");
    console.log(cipherText);*/
  };

  return (
    <BasicLayout image={bgImage}>
      {open && <SimpleDialog open={open} setOk={() => setOpen(false)} mode="alert" title={dialogContent.title} body={dialogContent.body} />}

      <MDBox>
        <Card style={{marginBottom: '14px', textAlign: 'center'}}>
          <MDBox pt={3} pb={3} px={3}>
            <MDTypography color="text" fontWeight="medium">
              VOOROOM OFFICE
            </MDTypography>
          </MDBox>
        </Card>

        <Card>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="아이디"
                  fullWidth
                  className="v-input-blod"
                  value={inputUserId}
                  onChange={e => {
                    setInputUserId(e.target.value);
                  }}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="비밀번호"
                  fullWidth
                  onChange={e => {
                    setInputPw(e.target.value);
                  }}
                />
              </MDBox>

              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth onClick={processLogin}>
                  접속하기
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </BasicLayout>
  );
}

export default Login;
