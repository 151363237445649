// --------------------------------------
// system constants
// --------------------------------------

// - 룸서비스 상태값
export const ROOM_SERVICE_STATE_1_DONE_PAY = 1; // 결제완료
export const ROOM_SERVICE_STATE_2_WAIT_ORDER = 10; // 접수대기
export const ROOM_SERVICE_STATE_3_1_NORMAL_RECEIPT = 20; // 정상접수
export const ROOM_SERVICE_STATE_3_2_DELAY_RECEIPT = 30; // 지연접수
export const ROOM_SERVICE_STATE_4_DELAY_DELIVERY = 40; // 배달지연
export const ROOM_SERVICE_STATE_5_COMPLETE_PICKUP = 50; // 픽업완료
export const ROOM_SERVICE_STATE_6_COMPLETE_DELIVERY = 60; // 배달완료
export const ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND = -1; // 환불완료

// - 컨시어지 상태값
export const CONCIERGE_STATE_1_DONE_PAY = 1; // 결제완료
export const CONCIERGE_STATE_10_NORMAL_RECEIPT = 10; // 접수완료
export const CONCIERGE_STATE_20_SCHEDULE_ING = 20; // 일정조율
export const CONCIERGE_STATE_30_SCHEDULE_DONE = 30; // 일정확인
export const CONCIERGE_STATE_40_SERVICE_DONE = 40; // 시행확인
export const CONCIERGE_UNDER_STATE_1_COMPLETE_REFUND = -1; // 환불완료

// - 로그인 마스터키
export const LOGIN_MASTER_KEY = 'vooroom1212*';

// - 고객관리 입장키
export const CUSTOMER_ENTER_KEY = 'vooroom0620*';

// - 앱키
export const APP_KEY_IS_LOGIN = 'office_is_login';

// - 룸서비스 로딩
export const APP_KEY_LOADING_ROOM_SERVICE = 'office_loading_room_service';

// - 웹소켓 URL
export const WEBSOCKET_SERVER_URL = 'wss://office-api.vooroom.kr/socket';
//export const WEBSOCKET_SERVER_URL = 'ws://localhost:8080/'; // TODO: 개발용
