import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import DashboardLayout from '../../../modules/LayoutContainers/DashboardLayout';
import HeaderNavbar from '../../../modules/Navbars/HeaderNavbar';
import * as Service from '../../../shared/apiService';
import * as Utils from '../../../shared/utils';
import '../../../assets/common.css';
import Icon from '@mui/material/Icon';
import MDTypography from '../../../components/MDTypography';
import {getRoomProductListByZoneCode} from '../../../shared/apiService';
import {GridLoader} from 'react-spinners';
import DataTable from '../../../modules/Tables/DataTable';
import Pagination from '@mui/material/Pagination';
import {setMiniSidenav, useMaterialUIController} from '../../../context';

function Product() {
  const navigate = useNavigate();
  const columns = [
    {Header: '번호', accessor: 'num', align: 'left', width: 100},
    {Header: '상품ID', accessor: 'pID', align: 'left', width: 100},
    {Header: '스토어명', accessor: 'sName', align: 'left', width: 100},
    {Header: '상품명', accessor: 'pName', align: 'left'},
    {Header: '단가', accessor: 'price', align: 'left'},
    {Header: '우선순위', accessor: 'priority', align: 'left'},
    {Header: '주문가능', accessor: 'isOrder', align: 'left'},
    {Header: '메뉴노출', accessor: 'isShow', align: 'left'},
    {Header: '타임설정', accessor: 'isTime', align: 'left'},
  ];

  // -- useEffect -----------------------------------------------------------------------
  const [controller, dispatch] = useMaterialUIController();
  const [rows, setRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const pagePerCount = 1000;
  const [pageTotal, setPageTotal] = useState(0);
  const [pageCurrentIdx, setPageCurrentIdx] = useState(1);
  const [loading, setLoding] = useState(true);

  useEffect(() => {
    // - 로그인 체크
    Utils.chkLogin().then(result => {
      if (result) {
        getProductList('4_DT2_G1');
      } else {
        navigate('/');
      }
    });
  }, []);

  useEffect(() => {
    // 전체 페이지 카운트 계산
    let tPage = Math.floor(tableData.length / pagePerCount);
    tableData.length % pagePerCount > 0 ? tPage++ : null;
    setPageTotal(tPage);
    renderTable();
  }, [tableData]);

  useEffect(() => {}, [rows]);

  useEffect(() => {
    renderTable();
  }, [pageCurrentIdx]);

  // -- feature -------------------------------------------------------------------------------
  const getProductList = zoneCode => {
    Service.getRoomProductListByZoneCode(zoneCode).then(result => {
      console.log(result);
      setTableData(result);
      setLoding(false);
    });
  };

  const getTimeText = isTime => {
    let result = 'ALL';

    if (isTime) {
      let parseFlag = isTime.split(',');
      if (parseFlag[0] === 'P') {
        result = '평일';
      } else if (parseFlag[0] === 'W') {
        result = '주말';
      } else {
        result = 'ONLY';
      }

      if (parseFlag[1] === 'A') {
        result += ' 런치';
      } else if (parseFlag[1] === 'B') {
        result += ' 디너';
      }
    }

    return result;
  };

  // -- handler -------------------------------------------------------------------------------
  const handlePageChange = (e, value) => {
    console.log('handlePagenationChange : ' + value);
    setPageCurrentIdx(value);
  };

  const handleOrderChange = (idx, value) => event => {
    const cIdx = (pageCurrentIdx - 1) * pagePerCount + idx;
    const productId = tableData[cIdx].product_id;
    Utils.showDialog('알림', '메뉴 주문 가능 상태를 변경하시겠습니까?').then(result => {
      if (result) {
        Service.updateRoomProductOrder(productId, value).then(() => {
          const tempTableData = Utils.deepCopy(tableData);
          tempTableData[cIdx].is_order = value;
          setTableData(tempTableData);
        });
      }
    });
  };

  const handleShowChange = (idx, value) => event => {
    const cIdx = (pageCurrentIdx - 1) * pagePerCount + idx;
    const productId = tableData[cIdx].product_id;
    Utils.showDialog('알림', '메뉴 노출 상태를 변경하시겠습니까?').then(result => {
      if (result) {
        Service.updateRoomProductShow(productId, value).then(() => {
          const tempTableData = Utils.deepCopy(tableData);
          tempTableData[cIdx].is_show = value;
          setTableData(tempTableData);
        });
      }
    });
  };

  // -- render -------------------------------------------------------------------------------
  const renderTable = () => {
    const renderItem = [];
    let begin = pagePerCount * (pageCurrentIdx - 1);
    let end = begin + pagePerCount;
    console.log('renderTable begin : ' + begin);
    console.log('renderTable end : ' + end);

    const mTableSlice = tableData.slice(begin, end);
    console.log(mTableSlice);

    mTableSlice.forEach((item, idx) => {
      renderItem.push({
        num: (
          <MDTypography variant="caption" color="dark" fontWeight="medium">
            {idx + pagePerCount * (pageCurrentIdx - 1) + 1}
          </MDTypography>
        ),
        pID: (
          <MDTypography variant="caption" color="dark" fontWeight="medium">
            {item.product_id}
          </MDTypography>
        ),
        sName: (
          <MDTypography variant="caption" color="dark" fontWeight="medium">
            {item.store_name}
          </MDTypography>
        ),
        pName: (
          <MDTypography variant="caption" color="primary" fontWeight="medium">
            {item.product_name}
          </MDTypography>
        ),
        price: (
          <MDTypography variant="caption" color="primary" fontWeight="medium">
            {Utils.wonPriceStr(item.price)}
          </MDTypography>
        ),
        priority: (
          <MDTypography variant="caption" color="dark" fontWeight="medium">
            {item.priority}
          </MDTypography>
        ),
        isOrder: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.is_order === 'Y' ? (
              <MDBox className="flag-box" style={{color: '#e91e63'}} onClick={handleOrderChange(idx, 'N')}>
                {item.is_order}
              </MDBox>
            ) : (
              <MDBox className="flag-box" onClick={handleOrderChange(idx, 'Y')}>
                {item.is_order}
              </MDBox>
            )}
          </MDTypography>
        ),
        isShow: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.is_show === 'Y' ? (
              <MDBox className="flag-box" style={{color: '#e91e63'}} onClick={handleShowChange(idx, 'N')}>
                {item.is_show}
              </MDBox>
            ) : (
              <MDBox className="flag-box" onClick={handleShowChange(idx, 'Y')}>
                {item.is_show}
              </MDBox>
            )}
          </MDTypography>
        ),
        isTime: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.is_time ? <MDBox style={{color: '#e91e63'}}>{getTimeText(item.is_time)}</MDBox> : <MDBox>{getTimeText(item.is_time)}</MDBox>}
          </MDTypography>
        ),
      });
    });
    setRows(renderItem);
  };

  return (
    <DashboardLayout>
      <HeaderNavbar title="상품관리 (룸서비스)" />

      <MDBox pt={2}>
        {loading && (
          <MDBox className="spinner-view">
            <GridLoader color={'#222'} loading={true} size={8} />
          </MDBox>
        )}

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card className="full-height">
              {tableData && (
                <MDBox style={{overflowY: 'auto', height: '100vh', margin: '4px'}}>
                  <MDBox className="thover">
                    <DataTable table={{columns, rows}} isSorted={false} entriesPerPage={false} showTotalEntries={false} noEndBorder />
                  </MDBox>
                  <MDBox pt={2} pb={2} sx={{display: 'flex', justifyContent: 'center', fontSize: '11px'}}>
                    <Pagination className="pnation" count={pageTotal} size="small" color="secondary" page={pageCurrentIdx} onChange={handlePageChange} />
                  </MDBox>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Product;
