import React, {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Pagination from '@mui/material/Pagination';
import MDBox from 'components/MDBox';
import DashboardLayout from '../../../modules/LayoutContainers/DashboardLayout';
import HeaderNavbar from '../../../modules/Navbars/HeaderNavbar';
import * as Service from '../../../shared/apiService';
import * as Utils from '../../../shared/utils';
import '../../../assets/common.css';
import DataTable from '../../../modules/Tables/DataTable';
import Icon from '@mui/material/Icon';
import MDTypography from '../../../components/MDTypography';
import {GridLoader} from 'react-spinners';
import TextField from '@mui/material/TextField';
import MDButton from '../../../components/MDButton';
import {APP_KEY_IS_LOGIN, CUSTOMER_ENTER_KEY, LOGIN_MASTER_KEY} from '../../../shared/Constants';
import SimpleDialog from '../../../modules/Dialog/Simple';
import CryptoJS from 'crypto-js';

function Customer() {
  const columns = [
    {Header: '사용자번호', accessor: 'num', align: 'left'},
    {Header: '이메일', accessor: 'email', align: 'left'},
    {Header: '간편로그인', accessor: 'snsType', align: 'left'},
    {Header: '닉네임', accessor: 'nickName', align: 'left'},
    {Header: '실명', accessor: 'realName', align: 'left'},
    {Header: '주소', accessor: 'addr', align: 'left'},
    {Header: '전화번호', accessor: 'phone', align: 'left'},
    {Header: '보유쿠폰', accessor: 'coupon', align: 'left'},
    {Header: '가입일', accessor: 'regDate', align: 'left'},
  ];

  // -- useEffect -----------------------------------------------------------------------
  const [rows, setRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const pagePerCount = 20;
  const [pageTotal, setPageTotal] = useState(0);
  const [pageCurrentIdx, setPageCurrentIdx] = useState(1);
  const [loading, setLoding] = useState(true);
  const [pwCheck, setPwCheck] = useState(false);
  const [inputPw, setInputPw] = useState('');
  const [inputCoupon, setInputCoupon] = useState('');
  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({title: '', body: ''});

  useEffect(() => {
    // - 로그인 체크
    Utils.chkLogin().then(result => {
      if (result) {
        getAllUserList();
      } else {
        navigate('/');
      }
    });
  }, []);

  useEffect(() => {
    // 전체 페이지 카운트 계산
    if (tableData.length > 0) {
      let tPage = Math.floor(tableData.length / pagePerCount);
      tableData.length % pagePerCount > 0 ? tPage++ : null;
      setPageTotal(tPage);
      renderTable();
    }
  }, [tableData]);

  useEffect(() => {}, [rows]);

  useEffect(() => {
    renderTable();
  }, [pageCurrentIdx]);

  // -- feature -------------------------------------------------------------------------------
  const getAllUserList = () => {
    Service.getAllUserList().then(result => {
      console.log(result);
      setTableData(result);
      setLoding(false);
    });
  };

  // -- handler -------------------------------------------------------------------------------
  const handlePageChange = (e, value) => {
    console.log('handlePagenationChange : ' + value);
    setPageCurrentIdx(value);
  };

  const handleCouponEnter = async () => {
    const couponList = inputCoupon.split('\n').filter(item => item > 0);
    console.log(couponList);

    if (couponList.length > 0) {
      Utils.showDialog('알림', couponList.length + '명 고객님께 할인 쿠폰을 발급 하겠습니까?').then(async result => {
        if (result) {
          for (const item of couponList) {
            await Service.updateFreeCouponPlus(item).then(result => console.log(item + '번 고객님 발급.'));
          }
          Utils.showAlert('알림', '쿠폰 발급을 성공하였습니다.');
          setInputCoupon('');
        } else {
          Utils.showAlert('알림', '쿠폰 발급을 취소했습니다.');
        }
      });
    } else {
      Utils.showAlert('알림', '쿠폰 발급하려는 고객ID를 입력해 주세요.');
    }
  };

  const handleEnter = async () => {
    if (inputPw === CUSTOMER_ENTER_KEY) {
      setPwCheck(true);
    } else {
      setDialogContent({
        title: '안내',
        body: '비밀번호가 일치하지 않습니다.',
      });
      setOpen(true);
    }

    // TODO: 전화번호 암호화 테스트키 (23.05.13)
    /*const testTxt = '01091665441';
    const cipherText = CryptoJS.AES.encrypt(testTxt, 'vooroomKey').toString();
    const parseText = cipherText.replaceAll('+', ' ');
    console.log('[부룸 암호화 변환기]');
    console.log('[phoneNumber] : ' + testTxt);
    console.log('[cipherText] : ' + cipherText);
    console.log('[parseText] : ' + parseText);*/
  };

  // -- render -------------------------------------------------------------------------------
  const renderTable = () => {
    const renderItem = [];
    let begin = pagePerCount * (pageCurrentIdx - 1);
    let end = begin + pagePerCount;
    console.log('renderTable begin : ' + begin);
    console.log('renderTable end : ' + end);

    const mTableSlice = tableData.slice(begin, end);
    console.log(mTableSlice);

    mTableSlice.forEach((item, idx) => {
      renderItem.push({
        num: (
          <MDTypography variant="caption" color="dark" fontWeight="light">
            {item.uuid}
          </MDTypography>
        ),
        email: (
          <MDTypography variant="caption" color="dark" fontWeight="light">
            {item.email}
          </MDTypography>
        ),
        snsType: (
          <MDTypography variant="caption" color="dark" fontWeight="light">
            {item.sns_type}
          </MDTypography>
        ),
        nickName: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.nick_name}
          </MDTypography>
        ),
        realName: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.real_name ? item.real_name : ''}
          </MDTypography>
        ),
        addr: (
          <MDTypography variant="caption" color="primary" fontWeight="medium">
            {item.room_id ? item.room_id : ''}
          </MDTypography>
        ),
        phone: (
          <MDTypography variant="caption" color="dark" fontWeight="light">
            {item.phone ? Utils.phoneRex(Utils.getDecryptPhoneNumber(item.phone)) : ''}
          </MDTypography>
        ),
        coupon: (
          <MDTypography variant="caption" color="dark" fontWeight="light">
            {item.free_coupon && item.free_coupon}
          </MDTypography>
        ),
        regDate: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {Utils.getOrderDateStrType3(item.create_date)}
          </MDTypography>
        ),
      });
    });
    setRows(renderItem);
  };

  return (
    <DashboardLayout>
      <HeaderNavbar title="고객관리" />
      {open && <SimpleDialog open={open} setOk={() => setOpen(false)} mode="alert" title={dialogContent.title} body={dialogContent.body} />}

      <MDBox pt={2}>
        {loading && (
          <MDBox className="spinner-view">
            <GridLoader color={'#222'} loading={true} size={8} />
          </MDBox>
        )}

        {pwCheck ? (
          <Grid container spacing={6}>
            <Grid item xs={10}>
              <Card className="full-height">
                {tableData && (
                  <MDBox style={{overflowY: 'auto', height: '100vh', margin: '4px'}}>
                    <MDBox className="thover">
                      <DataTable table={{columns, rows}} isSorted={false} entriesPerPage={false} showTotalEntries={false} noEndBorder />
                    </MDBox>
                    <MDBox pt={2} pb={2} sx={{display: 'flex', justifyContent: 'center', fontSize: '11px'}}>
                      <Pagination className="pnation" count={pageTotal} size="small" color="secondary" page={pageCurrentIdx} onChange={handlePageChange} />
                    </MDBox>
                  </MDBox>
                )}
              </Card>
            </Grid>
            <Grid item xs={2}>
              <Card className="full-height" style={{padding: '20px'}}>
                <MDBox style={{marginBottom: '20px'}}>
                  <TextField
                    fullWidth
                    label="쿠폰 발급 고객ID"
                    multiline
                    rows={46}
                    variant="standard"
                    size={'medium'}
                    value={inputCoupon}
                    onChange={e => {
                      setInputCoupon(e.target.value);
                    }}
                  />
                </MDBox>
                <MDBox style={{height: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                  <MDButton variant="gradient" color="info" fullWidth onClick={handleCouponEnter}>
                    쿠폰 발급
                  </MDButton>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card className="full-height">
                <MDBox className="empty-nodata">
                  <TextField
                    label="관계자외 절대 출입 금지!"
                    type="password"
                    variant="outlined"
                    onChange={e => {
                      setInputPw(e.target.value);
                    }}
                    sx={{width: '200px'}}
                  />
                  <MDButton variant="gradient" color="info" style={{marginLeft: '10px', width: '90px'}} onClick={handleEnter}>
                    확인
                  </MDButton>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Customer;
