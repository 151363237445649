import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import DashboardLayout from '../../../modules/LayoutContainers/DashboardLayout';
import HeaderConciergeNavbar from '../../../modules/Navbars/HeaderConciergeNavbar';
import * as Service from '../../../shared/apiService';
import * as Utils from '../../../shared/utils';
import '../../../assets/common.css';
import MDTypography from '../../../components/MDTypography';
import DataTable from '../../../modules/Tables/DataTable';
import Pagination from '@mui/material/Pagination';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as Constants from '../../../shared/Constants';
import dayjs from 'dayjs';
import {setHeaderNavbarButtonCmd, setMiniSidenav, useMaterialUIController, setConciergeOrderData} from '../../../context';
import ringer from '../../../assets/sound/flipdish-ringer.mp3';
import {CONCIERGE_UNDER_STATE_1_COMPLETE_REFUND, ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND} from '../../../shared/Constants';
import {getConciergeType, phone픈Rex} from '../../../shared/utils';
const audio = new Audio(ringer);
audio.loop = false;

function Concierge() {
  const navigate = useNavigate();
  const columns = [
    {Header: '주문일시', accessor: 'orderDate', align: 'left', width: '90px'},
    {Header: '주소', accessor: 'addr', align: 'left'},
    {Header: '고객정보', accessor: 'cPhone', align: 'left', width: '90px'},
    {Header: '스토어', accessor: 'store', align: 'left'},
    {Header: '상품', accessor: 'productName', align: 'left'},
    {Header: '수량', accessor: 'count', align: 'left', width: '90px'},
    {Header: '단가', accessor: 'price', align: 'left', width: '90px'},
    {Header: '결제금액', accessor: 'pay', align: 'left', width: '90px'},
    {Header: '서비스 일시', accessor: 'serviceDate', align: 'left', width: '90px'},
    {Header: 'BOID 상태', accessor: 'boidCycle', align: 'left', width: '90px'},
    {Header: 'POID 상태', accessor: 'poidCycle', align: 'left', width: '90px'},
  ];

  // -- useEffect -----------------------------------------------------------------------
  const [controller, dispatch] = useMaterialUIController();
  const [rows, setRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const pagePerCount = 20;
  const [pageTotal, setPageTotal] = useState(0);
  const [prevTotalCount, setPrevTotalCount] = useState(null);
  const [pageCurrentIdx, setPageCurrentIdx] = useState(1);
  const {headerNavbarButtonCmd} = controller;

  useEffect(() => {
    // - 로그인 체크
    Utils.chkLogin().then(result => {
      if (result) {
      } else {
        navigate('/');
      }
    });
    // 최소화
    setMiniSidenav(dispatch, true);

    // 정보가져오기
    getOrderData();

    // 자동 업데이트 10분 주기
    console.log('자동 갱신 타이머 시작...(10분)');
    setInterval(() => {
      console.log('>> 자동 타이머 갱신 !!');
      setHeaderNavbarButtonCmd(dispatch, 'concierge_refesh_auto');
    }, 600000); //600000
  }, []);

  useEffect(() => {
    // 전체 페이지 카운트 계산
    if (tableData.length > 0) {
      let tPage = Math.floor(tableData.length / pagePerCount);
      tableData.length % pagePerCount > 0 ? tPage++ : null;
      setPageTotal(tPage);
      renderTable();
    }
  }, [tableData]);

  useEffect(() => {
    renderTable();
  }, [pageCurrentIdx]);

  // 상단 콘트롤 버튼
  useEffect(() => {
    if (headerNavbarButtonCmd !== '') {
      console.log('[CMD] ' + headerNavbarButtonCmd);

      switch (headerNavbarButtonCmd) {
        case 'concierge_refesh':
          setTableData([]);
          getOrderData();
          Utils.showAlert('알림', '컨시어지 목록 새로고침 하였습니다.').then();
          break;
        case 'concierge_refesh_auto':
          setTableData([]);
          getOrderData();
          console.log('컨시어지 목록 자동 새로고침!');
          break;
        default:
          break;
      }
    }
    setHeaderNavbarButtonCmd(dispatch, '');
  }, [headerNavbarButtonCmd]);

  // -- feature -------------------------------------------------------------------------------

  const serverPayCancelTotal = (tid, orderId) => {
    // - 전체환불 처리
    return new Promise(function (resolve, reject) {
      const cancelOrderId = Utils.getCancelPayOrderId(orderId);
      Service.serverPayCancelTotal(tid, cancelOrderId).then(result => {
        console.log(result);
        if (result.resultCode === '0000') {
          Utils.showAlert('성공', '전체 환불 처리 성공하였습니다. (POID)').then();
          resolve(true);
        } else {
          // 환불 처리 실패
          Utils.showAlert('실패', '[ ' + result.resultCode + ' ] ' + result.resultMsg).then();
          resolve(false);
        }
      });
    });
  };

  const serverPayCancelPartial = (tid, orderId, amount) => {
    // - 부분환불 처리
    return new Promise(function (resolve, reject) {
      const cancelOrderId = Utils.getCancelPayOrderId(orderId);
      Service.serverPayCancelPartial(tid, cancelOrderId, amount).then(result => {
        console.log(result);
        if (result.resultCode === '0000') {
          Utils.showAlert('성공', '부분 환불 처리 성공하였습니다. (BOID)');
          resolve(true);
        } else {
          // 환불 처리 실패
          Utils.showAlert('실패', '[ ' + result.resultCode + ' ] ' + result.resultMsg).then();
          resolve(false);
        }
      });
    });
  };

  const getPoidMenuName = mPoid => {
    // - POID 묶음주문에 대해서 대표 메뉴를 선정해 반환.
    let mPrice = 0;
    let mainMenuName = '';
    let mCount = 0;
    tableData.forEach((item, idx) => {
      // 단품 가격이 가장 비싼 메뉴를 대표 메뉴로 선정함.
      if (item.toid === mPoid) {
        // 환불된 메뉴는 제외
        if (item.boid_state !== Constants.CONCIERGE_UNDER_STATE_1_COMPLETE_REFUND) {
          const finalPrice = item.boid_price;
          if (finalPrice > mPrice) {
            mPrice = finalPrice;
            mainMenuName = item.product_name;
          }
          mCount++;
        }
      }
    });

    return mCount > 1 ? mainMenuName + ' 외 ' + (mCount - 1) : mainMenuName;
  };

  const getPoidMenuCount = mPoid => {
    // - POID 묶음주문에 대해서 대표 메뉴를 선정해 반환.
    let mCount = 0;
    tableData.forEach((item, idx) => {
      // 단품 가격이 가장 비싼 메뉴를 대표 메뉴로 선정함.
      if (item.toid === mPoid) {
        mCount += item.count;
      }
    });

    return mCount;
  };

  const getOrderData = () => {
    Service.getConciergeOrderTotalList().then(async result => {
      console.log(result);
      setTableData(result);
      setPrevTotalCount(result.length);

      // - 신규주문 발생
      console.log('#1 result.length : ' + result.length);
      console.log('#2 prevTotalCount : ' + prevTotalCount);
      if (prevTotalCount !== null && result.length > prevTotalCount) {
        audio.play();
        Utils.showAlert('알림', '신규 주문이 도착하였습니다.').then();
      }

      let sumPrice = 0;
      let cntPoid = 0;
      let prevToid = '';
      result.forEach((item, idx) => {
        if (item.poid_state !== CONCIERGE_UNDER_STATE_1_COMPLETE_REFUND && prevToid !== item.toid) {
          sumPrice += item.pay_price;
          prevToid = item.toid;
          cntPoid++;
        }
      });

      // 부분환불 제외
      result.forEach((item, idx) => {
        if (item.poid_state !== CONCIERGE_UNDER_STATE_1_COMPLETE_REFUND && item.boid_state === CONCIERGE_UNDER_STATE_1_COMPLETE_REFUND) {
          sumPrice -= item.boid_price * item.count;
        }
      });

      setConciergeOrderData(dispatch, {
        tTotalPoid: cntPoid,
        tTotalOrderPrice: sumPrice,
      });
    });
  };

  // - BOID 상태 처리 (R)
  const handleBoidChangeTypeR = (item, idx, state) => {
    const prevState = tableData[idx].poid_state;

    if (prevState < 0) {
      Utils.showAlert('알림', '이미 환불 처리된 상태에서는 수정이 불가합니다.').then();
    } else {
      // - 알림톡 파라미터 데이터 모음
      const dateTime = Utils.getOrderDateTimeString(item.order_date); // 주문일시
      const poid = item.toid;
      const storeName = item.store_name ? item.store_name : '';
      const menuName = item.product_name;
      const menuCount = item.count;
      const mRecipientNo = item.phone ? Utils.getDecryptPhoneNumber(item.phone) : '';
      const mCcancelAmt = item.boid_price * item.count;

      if (state < 0) {
        Utils.showDialog('알림', '부분 환불 처리를 진행 할까요?').then(result => {
          if (result) {
            // BOID 부분 주문 취소 (부분환불)
            serverPayCancelPartial(item.tid, item.order_id, mCcancelAmt).then(result => {
              if (result) {
                Service.updateConciergeBoidStatus(item.boid, state).then(result => {
                  console.log('[handleBoidChangeTypeR]', item.boid);
                  const mTableData = tableData;
                  setTableData([]);
                  mTableData[idx].boid_state = state;
                  setTableData(mTableData);
                });

                // 부분 환불 알림톡 전송 (BOID)
                const mTemplateCode = 'Con-Refund_part';
                const mTemplateParameter = {
                  dateTime: dateTime,
                  poid: poid,
                  refundCharge: mCcancelAmt + '원',
                  storeName: storeName,
                  menuName: menuName,
                  menuCount: menuCount,
                };
                Service.sendKakaoAlimTalk(mTemplateCode, mRecipientNo, mTemplateParameter).then();
              }
            });
          }
        });
      } else {
        Utils.showAlert('실패', 'BOID는 부분환불 처리만 가능합니다.').then();
      }
    }
  };

  // - POID 상태 처리 (R)
  const handlePoidChangeTypeR = (item, idx, state) => {
    const prevState = tableData[idx].poid_state;
    const serviceDate = item.service_date ? Utils.getOrderDateTimeString(item.service_date) : null; // 서비스 시행 일시;

    if (prevState < 0) {
      Utils.showAlert('알림', '이미 환불 처리된 상태에서는 수정이 불가합니다.').then();
    } else if ((state === Constants.CONCIERGE_STATE_30_SCHEDULE_DONE || state === Constants.CONCIERGE_STATE_40_SERVICE_DONE) && serviceDate === null) {
      Utils.showAlert('실패', '서비스 일시 정보가 없습니다.').then();
    } else {
      // - 알림톡 파라미터 데이터 모음
      const dateTime = Utils.getOrderDateTimeString(item.order_date); // 주문일시
      const poid = item.toid;
      const storeName = item.store_name ? item.store_name : '';
      const menuName = getPoidMenuName(poid);
      const menuCount = getPoidMenuCount(poid);
      const mRecipientNo = item.phone ? Utils.getDecryptPhoneNumber(item.phone) : '';
      const payPrice = Utils.wonPriceStr(item.pay_price);

      if (state < 0) {
        Utils.showDialog('알림', '전체 환불 처리를 진행 할까요?').then(result => {
          if (result) {
            // POID 전체 주문 취소 (전체환불)
            serverPayCancelTotal(item.tid, item.order_id).then(result => {
              if (result) {
                Service.updateConciergePoidStatus(item.toid, state).then(result => {
                  console.log('[handlePoidChangeTypeR]', item.toid);
                  const mainToid = item.toid;
                  const mTableData = tableData;
                  setTableData([]);
                  mTableData.forEach((item, idx) => {
                    if (mainToid === item.toid) {
                      mTableData[idx].poid_state = state;
                      mTableData[idx].boid_state = state;
                      Service.updateConciergeBoidStatus(mTableData[idx].boid, state).then();
                    }
                  });
                  setTableData(mTableData);
                });
                // 전체 환불 알림톡 전송 (POID)
                const mTemplateCode = 'Con-Refund';
                const mTemplateParameter = {
                  dateTime: dateTime,
                  poid: poid,
                  refundCharge: payPrice + '원',
                  storeName: storeName,
                  menuName: menuName,
                  menuCount: menuCount,
                };
                Service.sendKakaoAlimTalk(mTemplateCode, mRecipientNo, mTemplateParameter).then();
              }
            });
          }
        });
      } else {
        // 일반 상태 변경
        const notiType = Utils.getConciergeServiceStateStr(state); // 고객 알림톡(카톡) 발송
        Service.updateConciergePoidStatus(item.toid, state).then(result => {
          console.log('[handlePoidChangeTypeR]', item.toid);
          const mainToid = item.toid;
          const mTableData = tableData;
          setTableData([]);
          mTableData.forEach((item, idx) => {
            if (mainToid === item.toid) {
              mTableData[idx].poid_state = state;
              mTableData[idx].boid_state = state;
              Service.updateConciergeBoidStatus(mTableData[idx].boid, state).then();
            }
          });
          setTableData(mTableData);
        });
        if (notiType && state > 1) {
          Utils.showDialog('알림', '고객님께 [ ' + notiType + ' ] 알림톡을 전송 하시겠습니까?').then(result => {
            if (result) {
              // 알림톡 전송
              let mTemplateCode = null;
              let mRecipientNo = item.phone ? Utils.getDecryptPhoneNumber(item.phone) : '';
              let mTemplateParameter = null;
              switch (state) {
                case Constants.CONCIERGE_STATE_10_NORMAL_RECEIPT: // 접수완료
                  mTemplateCode = 'Con-Oper_order';
                  mTemplateParameter = {
                    dateTime: dateTime,
                    poid: poid,
                    storeName: storeName,
                    menuName: menuName,
                    menuCount: menuCount,
                  };
                  break;
                case Constants.CONCIERGE_STATE_20_SCHEDULE_ING: // 일정조율
                  mTemplateCode = 'Con-R-Scheduling';
                  mTemplateParameter = {
                    dateTime: dateTime,
                    poid: poid,
                    storeName: storeName,
                    menuName: menuName,
                    menuCount: menuCount,
                  };
                  break;
                case Constants.CONCIERGE_STATE_30_SCHEDULE_DONE: // 일정확인
                  mTemplateCode = 'Con-R-Schedule_done';
                  mTemplateParameter = {
                    serviceDate: serviceDate,
                    poid: poid,
                    storeName: storeName,
                    menuName: menuName,
                    menuCount: menuCount,
                  };
                  break;
                case Constants.CONCIERGE_STATE_40_SERVICE_DONE: // 시행확인
                  mTemplateCode = 'Con-R-Service_done';
                  mTemplateParameter = {
                    serviceDate: serviceDate,
                    poid: poid,
                    storeName: storeName,
                    menuName: menuName,
                    menuCount: menuCount,
                  };
                  break;
                default:
                  break;
              }

              if (mTemplateCode && mRecipientNo && mTemplateParameter) {
                Service.sendKakaoAlimTalk(mTemplateCode, mRecipientNo, mTemplateParameter).then();
              } else {
                Utils.showAlert('실패', '알림톡 전송에 실패 하였습니다. (파라미터 오류)').then();
                console.log('handlePoidChangeTypeR', 'sendKakaoAlimTalk params null.');
              }
            }
          });
        } else {
          console.log('handlePoidChangeTypeR', 'notiType is null / state 1');
        }
      }
    }
  };

  // - BOID 상태 처리 (T)
  const handleBoidChangeTypeT = (item, idx, state) => {
    const prevState = tableData[idx].poid_state;

    if (prevState < 0) {
      Utils.showAlert('알림', '이미 환불 처리된 상태에서는 수정이 불가합니다.').then();
    } else {
      // - 알림톡 파라미터 데이터 모음
      const dateTime = Utils.getOrderDateTimeString(item.order_date); // 주문일시
      const poid = item.toid;
      const storeName = item.store_name ? item.store_name : '';
      const menuName = item.product_name;
      const menuCount = item.count;
      const mRecipientNo = item.phone ? Utils.getDecryptPhoneNumber(item.phone) : '';
      const mCcancelAmt = item.boid_price * item.count;

      if (state < 0) {
        Utils.showDialog('알림', '부분 환불 처리를 진행 할까요?').then(result => {
          if (result) {
            // BOID 부분 주문 취소 (부분환불)
            serverPayCancelPartial(item.tid, item.order_id, mCcancelAmt).then(result => {
              if (result) {
                Service.updateConciergeBoidStatus(item.boid, state).then(result => {
                  console.log('[handleBoidChangeTypeR]', item.boid);
                  const mTableData = tableData;
                  setTableData([]);
                  mTableData[idx].boid_state = state;
                  setTableData(mTableData);
                });

                // 부분 환불 알림톡 전송 (BOID)
                const mTemplateCode = 'Con-Refund_part';
                const mTemplateParameter = {
                  dateTime: dateTime,
                  poid: poid,
                  refundCharge: mCcancelAmt + '원',
                  storeName: storeName,
                  menuName: menuName,
                  menuCount: menuCount,
                };
                Service.sendKakaoAlimTalk(mTemplateCode, mRecipientNo, mTemplateParameter).then();
              }
            });
          }
        });
      } else {
        Utils.showAlert('실패', 'BOID는 부분환불 처리만 가능합니다.').then();
      }
    }
  };

  // - POID 상태 처리 (T)
  const handlePoidChangeTypeT = (item, idx, state) => {
    const prevState = tableData[idx].poid_state;

    if (prevState < 0) {
      Utils.showAlert('알림', '이미 환불 처리된 상태에서는 수정이 불가합니다.').then();
    } else {
      // - 알림톡 파라미터 데이터 모음
      const dateTime = Utils.getOrderDateTimeString(item.order_date); // 주문일시
      const poid = item.toid;
      const storeName = item.store_name ? item.store_name : '';
      const menuName = getPoidMenuName(poid);
      const menuCount = getPoidMenuCount(poid);
      const mRecipientNo = item.phone ? Utils.getDecryptPhoneNumber(item.phone) : '';
      const storePhone = item.tel;
      const payPrice = Utils.wonPriceStr(item.pay_price);

      if (state < 0) {
        Utils.showDialog('알림', '전체 환불 처리를 진행 할까요?').then(result => {
          if (result) {
            // POID 전체 주문 취소 (전체환불)
            serverPayCancelTotal(item.tid, item.order_id).then(result => {
              if (result) {
                Service.updateConciergePoidStatus(item.toid, state).then(result => {
                  console.log('[handlePoidChangeTypeR]', item.toid);
                  const mainToid = item.toid;
                  const mTableData = tableData;
                  setTableData([]);
                  mTableData.forEach((item, idx) => {
                    if (mainToid === item.toid) {
                      mTableData[idx].poid_state = state;
                      mTableData[idx].boid_state = state;
                      Service.updateConciergeBoidStatus(mTableData[idx].boid, state).then();
                    }
                  });
                  setTableData(mTableData);
                });
                // 전체 환불 알림톡 전송 (POID)
                const mTemplateCode = 'Con-Refund';
                const mTemplateParameter = {
                  dateTime: dateTime,
                  poid: poid,
                  refundCharge: payPrice + '원',
                  storeName: storeName,
                  menuName: menuName,
                  menuCount: menuCount,
                };
                Service.sendKakaoAlimTalk(mTemplateCode, mRecipientNo, mTemplateParameter).then();
              }
            });
          }
        });
      } else {
        // 일반 상태 변경
        const notiType = Utils.getConciergeServiceStateStr(state); // 고객 알림톡(카톡) 발송
        Service.updateConciergePoidStatus(item.toid, state).then(result => {
          console.log('[handlePoidChangeTypeR]', item.toid);
          const mainToid = item.toid;
          const mTableData = tableData;
          setTableData([]);
          mTableData.forEach((item, idx) => {
            if (mainToid === item.toid) {
              mTableData[idx].poid_state = state;
              mTableData[idx].boid_state = state;
              Service.updateConciergeBoidStatus(mTableData[idx].boid, state).then();
            }
          });
          setTableData(mTableData);
        });
        if (notiType && state > 1) {
          Utils.showDialog('알림', '고객님께 [ ' + notiType + ' ] 알림톡을 전송 하시겠습니까?').then(result => {
            if (result) {
              // 알림톡 전송
              let mTemplateCode = null;
              let mRecipientNo = item.phone ? Utils.getDecryptPhoneNumber(item.phone) : '';
              let mTemplateParameter = null;
              switch (state) {
                case Constants.CONCIERGE_STATE_10_NORMAL_RECEIPT: // 접수완료
                  mTemplateCode = 'Con-Oper_order';
                  mTemplateParameter = {
                    dateTime: dateTime,
                    poid: poid,
                    storeName: storeName,
                    menuName: menuName,
                    menuCount: menuCount,
                  };
                  break;
                case Constants.CONCIERGE_STATE_40_SERVICE_DONE: // 시행확인
                  mTemplateCode = 'Con-T-Serv_confirm';
                  mTemplateParameter = {
                    customerName: '', // 고객명 -> 삭제 예정
                    customerPhone: Utils.phoneRex(mRecipientNo), // 고객연락처
                    storePhone: storePhone, // 스토어연락처
                    dateTime: dateTime, // 신청일시
                    poid: poid, // 신청번호
                    storeName: storeName,
                    menuName: menuName, // 상품명
                    menuCount: menuCount, // 상품수량
                  };
                  break;
                default:
                  break;
              }

              if (mTemplateCode && mRecipientNo && mTemplateParameter) {
                Service.sendKakaoAlimTalk(mTemplateCode, mRecipientNo, mTemplateParameter).then();
              } else {
                Utils.showAlert('실패', '알림톡 전송에 실패 하였습니다. (파라미터 오류)').then();
                console.log('handlePoidChangeTypeR', 'sendKakaoAlimTalk params null.');
              }
            }
          });
        } else {
          console.log('handlePoidChangeTypeR', 'notiType is null / state 1');
        }
      }
    }
  };

  // -- handler -------------------------------------------------------------------------------
  const handlePageChange = (e, value) => {
    console.log('handlePagenationChange : ' + value);
    setPageCurrentIdx(value);
  };

  // -- render -------------------------------------------------------------------------------
  const renderTable = () => {
    const renderItem = [];
    let prevPoid = '';

    tableData.forEach((item, idx) => {
      // 주문번호
      const dateTime = Utils.getOrderDateStrType3(item.order_date).split(' ');

      // - 동호수 주소
      let address;
      if (item.room_id) {
        address = item.room_id.split('/');
      } else {
        address = 'N/A(탈퇴)';
      }

      // - 휴대폰 번호 복호화 처리
      let parsePhoneNum = item.phone ? Utils.getDecryptPhoneNumber(item.phone) : 'N/A(탈퇴)';

      // - 스토어 링크 주소
      let store_link_url = item.region_code ? 'https://vooroom-store.kr/' + item.region_code.toLowerCase() + '/' + item.store_code : null;

      renderItem.push({
        orderDate: prevPoid !== item.toid && (
          <MDTypography variant="caption" color="dark" fontWeight="medium">
            <span style={{fontSize: '12px', fontWeight: '300'}}>({item.toid})</span>
            <br />
            {dateTime[0]}
            <br />
            {dateTime[1]}
          </MDTypography>
        ),
        addr: prevPoid !== item.toid && (
          <MDTypography variant="caption" color="dark" fontWeight="medium">
            {address[0]}
            <br />
            {address[1]}동 {address[2]}호
          </MDTypography>
        ),
        cPhone: (
          <MDTypography variant="caption" color="dark" fontWeight="medium">
            {prevPoid !== item.toid && '(' + item.user_id + ')'}
            <br />
            {prevPoid !== item.toid && parsePhoneNum && Utils.phoneRex(parsePhoneNum)}
            <br />
            {prevPoid !== item.toid && item.real_name && item.real_name}
          </MDTypography>
        ),
        store: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            <a href={store_link_url} target="_blank">
              {item.store_name}
            </a>
          </MDTypography>
        ),
        productName: (
          <MDTypography variant="caption" color="primary" fontWeight="medium">
            {item.product_name}
          </MDTypography>
        ),
        count: (
          <MDTypography variant="caption" color="primary" fontWeight="medium">
            {item.count}
          </MDTypography>
        ),
        price: (
          <MDTypography variant="caption" color="dark" fontWeight="light">
            {item.boid_price && Utils.wonPriceStr(item.boid_price)}
          </MDTypography>
        ),
        pay: (
          <MDTypography variant="caption" color="dark" fontWeight="medium">
            {prevPoid !== item.toid && item.pay_price && Utils.wonPriceStr(item.pay_price)}
          </MDTypography>
        ),
        serviceDate: prevPoid !== item.toid && (
          <MDBox className={item.service_date ? 'concierge-date-picker' : 'concierge-date-picker-empty'} style={{width: '320px', display: 'flex'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
              <DateTimePicker
                renderInput={params => <TextField {...params} />}
                value={item.service_date ? item.service_date : null}
                toolbarPlaceholder={'-'}
                onChange={newValue => {
                  const mTableData = tableData;
                  mTableData[idx].service_date = Utils.getOrderDateStrType3(new Date(newValue));
                  setTableData(mTableData);
                  renderTable();
                }}
              />
            </LocalizationProvider>
            <MDBox>
              <Button
                variant="outlined"
                color="secondary"
                sx={{backgroundColor: '#f0f2f5', borderColor: '#d2d6da', color: '#495057', width: 80, height: 44, marginLeft: '8px'}}
                onClick={() => {
                  Service.updateConciergeServiceDate(item.toid, item.service_date).then(() => {
                    // - 새로고침
                    setTableData([]);
                    getOrderData();
                    Utils.showAlert('알림', '서비스 날짜가 변경되었습니다.').then();
                  });
                }}>
                적용
              </Button>
            </MDBox>
          </MDBox>
        ),
        boidCycle: (
          <FormControl sx={{m: 1, minWidth: 96, maxWidth: 96, padding: 0, margin: 0}} size="small">
            {Utils.getConciergeType(item.store_code) === 'R' && (
              <Select
                labelId="demo-select-small"
                value={item.boid_state}
                sx={{
                  fontSize: '18px',
                  fontWeight: '600',
                  height: '44px',
                  backgroundColor: Utils.getConciergeSelectBoxColor(item.boid_state),
                }}
                onChange={e => {
                  const state = e.target.value;
                  handleBoidChangeTypeR(item, idx, state);
                }}>
                <MenuItem value={1}>결제완료</MenuItem>
                <MenuItem value={10}>접수완료</MenuItem>
                <MenuItem value={20}>일정조율(R)</MenuItem>
                <MenuItem value={30}>일정확인(R)</MenuItem>
                <MenuItem value={40}>시행완료(R)</MenuItem>
                <MenuItem value={0}>===========</MenuItem>
                <MenuItem value={-1}>환불완료</MenuItem>
              </Select>
            )}
            {Utils.getConciergeType(item.store_code) === 'T' && (
              <Select
                labelId="demo-select-small"
                value={item.boid_state}
                sx={{
                  fontSize: '18px',
                  fontWeight: '600',
                  height: '44px',
                  backgroundColor: Utils.getConciergeSelectBoxColor(item.boid_state),
                }}
                onChange={e => {
                  const state = e.target.value;
                  handleBoidChangeTypeT(item, idx, state);
                }}>
                <MenuItem value={1}>결제완료</MenuItem>
                <MenuItem value={10}>접수완료</MenuItem>
                <MenuItem value={40}>서비스확정(T)</MenuItem>
                <MenuItem value={0}>===========</MenuItem>
                <MenuItem value={-1}>환불완료</MenuItem>
              </Select>
            )}
          </FormControl>
        ),
        poidCycle: prevPoid !== item.toid && (
          <FormControl sx={{m: 1, minWidth: 96, maxWidth: 96, padding: 0, margin: 0}} size="small">
            {Utils.getConciergeType(item.store_code) === 'R' && (
              <Select
                labelId="demo-select-small"
                value={item.poid_state}
                sx={{
                  fontSize: '18px',
                  fontWeight: '600',
                  height: '44px',
                  backgroundColor: Utils.getConciergeSelectBoxColor(item.poid_state),
                }}
                onChange={e => {
                  const state = e.target.value;
                  handlePoidChangeTypeR(item, idx, state);
                }}>
                <MenuItem value={1}>결제완료</MenuItem>
                <MenuItem value={10}>접수완료</MenuItem>
                <MenuItem value={20}>일정조율(R)</MenuItem>
                <MenuItem value={30}>일정확인(R)</MenuItem>
                <MenuItem value={40}>시행완료(R)</MenuItem>
                <MenuItem value={0}>===========</MenuItem>
                <MenuItem value={-1}>환불완료</MenuItem>
              </Select>
            )}
            {Utils.getConciergeType(item.store_code) === 'T' && (
              <Select
                labelId="demo-select-small"
                value={item.poid_state}
                sx={{
                  fontSize: '18px',
                  fontWeight: '600',
                  height: '44px',
                  backgroundColor: Utils.getConciergeSelectBoxColor(item.poid_state),
                }}
                onChange={e => {
                  const state = e.target.value;
                  handlePoidChangeTypeT(item, idx, state);
                }}>
                <MenuItem value={1}>결제완료</MenuItem>
                <MenuItem value={10}>접수완료</MenuItem>
                <MenuItem value={40}>서비스확정(T)</MenuItem>
                <MenuItem value={0}>===========</MenuItem>
                <MenuItem value={-1}>환불완료</MenuItem>
              </Select>
            )}
          </FormControl>
        ),
      });
      prevPoid = item.toid;
    });
    setRows(renderItem);
  };

  return (
    <DashboardLayout>
      <HeaderConciergeNavbar />

      <MDBox pt={2}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card className="full-height">
              <MDBox style={{overflowY: 'auto', height: '100vh', margin: '4px'}}>
                <MDBox className="thover">
                  <DataTable table={{columns, rows}} isSorted={false} entriesPerPage={false} showTotalEntries={false} noEndBorder />
                </MDBox>

                <MDBox pt={2} pb={2} sx={{display: 'flex', justifyContent: 'center', fontSize: '11px'}}>
                  <Pagination className="pnation" count={pageTotal} size="small" color="secondary" page={pageCurrentIdx} onChange={handlePageChange} />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Concierge;
