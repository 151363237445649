import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import BasicLayout from "../authentication/components/BasicLayout";
import MDTypography from "../../components/MDTypography";
import Icon from "@mui/material/Icon";
import React from "react";
import "../../assets/common.css";
import PageLayout from "../../modules/LayoutContainers/PageLayout";

function Empty() {
  return (
    <PageLayout>
      <MDBox className="empty-view" p={2}>
        <Card className="empty-card">
          <MDBox>
            <Icon fontSize="large">not_interested</Icon>
            <MDTypography variant="h6" fontWeight="medium">
              잘못된 URL 링크 접속 입니다.
            </MDTypography>
            <br />
            <MDTypography variant="h6" fontWeight="medium">
              VOOROOM
            </MDTypography>
          </MDBox>
        </Card>
      </MDBox>
    </PageLayout>
  );
}

export default Empty;
