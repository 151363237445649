import axios from 'axios';

export const api = axios.create({
  baseURL: '/',
  withCredentials: true, // ajax 요청에 쿠키를 보내려면 필요.
});

export const CUSTOM_ERROR_HANDLE = {useCustomErrorHandle: true};

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // 호출측에서 직접 에러를 처리하겠다고 하면, 공통 처리 로직을 건너 뛴다.
    if (error.config?.useCustomErrorHandle === true) {
      return Promise.reject(error);
    }

    // TODO: 공통 에러 처리
    console.log('error', error);

    /*const tpError = getTpError(error);
    store.dispatch(showDialog({
        title: tpError.title,
        content: tpError.displayMessage,
        modalStyle: 'warning',
    }));*/

    return Promise.reject(error);
  },
);
