import {useState, useEffect, useMemo} from 'react';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Sidenav from 'modules/Sidenav';
import theme from 'assets/theme';
import themeDark from 'assets/theme-dark';
import routes from 'routes';
import {useMaterialUIController, setMiniSidenav} from 'context';
import './assets/common.css';
import Empty from './layouts/empty';
import Main from './layouts/office/main';
import RoomService from './layouts/office/roomService';
import Concierge from './layouts/office/concierge';
import Login from './layouts/office/login';
import * as Utils from './shared/utils';

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {miniSidenav, direction, sidenavColor, darkMode, layout, storeInfo} = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const {pathname} = useLocation();

  // Cache for the rtl
  useMemo(() => {}, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    /*    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }*/
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    /*    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }*/
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = allRoutes =>
    allRoutes.map(route => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />

      {layout === 'dashboard' && storeInfo && (
        <Sidenav color={sidenavColor} brandName="VOOROOM" routes={routes} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} />
      )}

      <Routes>
        <Route path="/logout" element={<Navigate to="/" />} />
        <Route exact path="/room" element={<RoomService />} key="room" />
        <Route exact path="/concierge" element={<Concierge />} key="concierge" />
        <Route exact path="/main" element={<Main />} key="main" />
        <Route exact path="/" element={<Login />} key="login" />
        {getRoutes(routes)}
        <Route path="*" element={<Empty />} />
      </Routes>
    </ThemeProvider>
  );
}
