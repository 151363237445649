import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DashboardLayout from '../../../modules/LayoutContainers/DashboardLayout';
import HeaderNavbar from '../../../modules/Navbars/HeaderNavbar';
import * as Service from '../../../shared/apiService';
import * as Utils from '../../../shared/utils';
import '../../../assets/common.css';
import MDTypography from '../../../components/MDTypography';
import {GridLoader} from 'react-spinners';
import DataTable from '../../../modules/Tables/DataTable';
import Pagination from '@mui/material/Pagination';
import {useMaterialUIController} from '../../../context';
import {updateRoomStoreHoliday} from '../../../shared/apiService';
import Icon from '@mui/material/Icon';

function Store() {
  const navigate = useNavigate();
  const columns = [
    {Header: '번호', accessor: 'num', align: 'left', width: 100},
    {Header: '스토어명', accessor: 'sName', align: 'left', width: 100},
    {Header: '전화번호', accessor: 'tel', align: 'left', width: 100},
    {Header: '위치', accessor: 'stair', align: 'left', width: 100},
    {Header: '조리시간', accessor: 'cooking', align: 'left', width: 100},
    {Header: '영업 상태', accessor: 'isOpen', align: 'left'},
    {Header: '영업 시작', accessor: 'openTime', align: 'left'},
    {Header: '영업 종료', accessor: 'closeTime', align: 'left'},
    {Header: '브레이크 시작', accessor: 'breakOpenTime', align: 'left'},
    {Header: '브레이크 종료', accessor: 'breakCloseTime', align: 'left'},
    {Header: '가맹점', accessor: 'isMember', align: 'left'},
    {Header: '수수료', accessor: 'commission', align: 'left'},
    {Header: '선결제', accessor: 'prePay', align: 'left'},
    {Header: '네이버', accessor: 'naver', align: 'left'},
    {Header: '정기휴무', accessor: 'holiday', align: 'left'},
  ];

  // -- useEffect -----------------------------------------------------------------------
  const [controller, dispatch] = useMaterialUIController();
  const [rows, setRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const pagePerCount = 200;
  const [pageTotal, setPageTotal] = useState(0);
  const [pageCurrentIdx, setPageCurrentIdx] = useState(1);
  const [loading, setLoding] = useState(true);

  useEffect(() => {
    // - 로그인 체크
    Utils.chkLogin().then(result => {
      if (result) {
        getStoreList('4_DT2_G1');
      } else {
        navigate('/');
      }
    });
  }, []);

  useEffect(() => {
    // 전체 페이지 카운트 계산
    let tPage = Math.floor(tableData.length / pagePerCount);
    tableData.length % pagePerCount > 0 ? tPage++ : null;
    setPageTotal(tPage);
    renderTable();
  }, [tableData]);

  useEffect(() => {}, [rows]);

  useEffect(() => {
    renderTable();
  }, [pageCurrentIdx]);

  // -- feature -------------------------------------------------------------------------------
  const getStoreList = zoneCode => {
    Service.getRoomStoreListByZoneCode(zoneCode).then(result => {
      Array.isArray(result) && setTableData(result);
      setLoding(false);
    });
  };

  // -- handler -------------------------------------------------------------------------------
  const handlePageChange = (e, value) => {
    console.log('handlePagenationChange : ' + value);
    setPageCurrentIdx(value);
  };

  const handleOpenChange = (idx, value) => event => {
    // 스토어 오픈 정보 수정
    const storeId = tableData[idx].store_id;
    Utils.showDialog('알림', '영업 상태를 변경 하시겠습니까?').then(result => {
      if (result) {
        Service.updateRoomStoreOpen(storeId, value).then(() => {
          const tempTableData = Utils.deepCopy(tableData);
          tempTableData[idx].is_open = value;
          setTableData(tempTableData);
        });
      }
    });
  };

  const handleCheckChange = (idx, yoil, week_holiday) => event => {
    week_holiday[yoil] = event.target.checked;
    let newStr = '';
    week_holiday.forEach((item, idx) => {
      if (item === true) {
        newStr === '' ? (newStr += idx + '') : (newStr += ',' + idx);
      }
    });
    const tempTableData = Utils.deepCopy(tableData);
    tempTableData[idx].holiday = newStr;

    // 휴무일 정보 업데이트
    const storeId = tempTableData[idx].store_id;
    const storeName = tempTableData[idx].store_name;
    console.log('[handleCheckChange] ' + storeId + ' / ' + newStr);
    Service.updateRoomStoreHoliday(storeId, newStr).then(() => {
      Utils.showAlert('알림', '[ ' + storeName + ' ] 정기 휴무일이 수정되었습니다.').then();
    });

    setTableData(tempTableData);
  };

  // -- render -------------------------------------------------------------------------------
  const renderTable = () => {
    const renderItem = [];
    let begin = pagePerCount * (pageCurrentIdx - 1);
    let end = begin + pagePerCount;
    console.log('renderTable begin : ' + begin);
    console.log('renderTable end : ' + end);

    const mTableSlice = tableData.slice(begin, end);

    mTableSlice.forEach((item, idx) => {
      // - 스토어 링크 주소
      const store_link_url = item.region_code ? 'https://vooroom-store.kr/' + item.region_code.toLowerCase() + '/' + item.store_code : null;

      //- 일, 월, 화, 수, 목, 금, 토
      const week_holiday = [false, false, false, false, false, false, false];

      // - 휴무체크
      if (item.holiday) {
        let parseHoliday = item.holiday.split(',');
        parseHoliday.forEach((item, idx) => {
          let mValue = Number(item);
          week_holiday[mValue] = true;
        });
      }

      renderItem.push({
        num: (
          <MDTypography variant="caption" color="dark" fontWeight="medium">
            {idx + pagePerCount * (pageCurrentIdx - 1) + 1}
          </MDTypography>
        ),
        sName:
          item.is_members === 'Y' ? (
            <MDTypography variant="caption" color="primary" fontWeight="medium">
              <a href={store_link_url} target="_blank" style={{color: '#e91e63'}}>
                {item.store_name}
              </a>
            </MDTypography>
          ) : (
            <MDTypography variant="caption" color="dark" fontWeight="medium">
              {item.store_name}
            </MDTypography>
          ),
        tel:
          item.is_members === 'Y' ? (
            <MDTypography variant="caption" color="primary" fontWeight="medium">
              {item.tel}
            </MDTypography>
          ) : (
            <MDTypography variant="caption" color="dark" fontWeight="medium">
              {item.tel}
            </MDTypography>
          ),
        stair: (
          <MDTypography variant="caption" color="primary" fontWeight="medium">
            {item.stair && item.stair + '층'}
          </MDTypography>
        ),
        cooking: (
          <MDTypography variant="caption" color="primary" fontWeight="medium">
            {item.cooking_time && item.cooking_time + '분'}
          </MDTypography>
        ),
        isOpen:
          item.is_open === 'Y' ? (
            <MDTypography variant="caption" fontWeight="medium">
              <MDBox className="flag-box" style={{color: '#e91e63'}} onClick={handleOpenChange(idx, 'N')}>
                {item.is_open}
              </MDBox>
            </MDTypography>
          ) : (
            <MDTypography variant="caption" fontWeight="medium">
              <MDBox className="flag-box" onClick={handleOpenChange(idx, 'Y')}>
                {item.is_open}
              </MDBox>
            </MDTypography>
          ),
        openTime: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.open_time}
          </MDTypography>
        ),
        closeTime: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.close_time}
          </MDTypography>
        ),
        breakOpenTime: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.break_start_time}
          </MDTypography>
        ),
        breakCloseTime: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.break_end_time}
          </MDTypography>
        ),
        isMember:
          item.is_members === 'Y' ? (
            <MDTypography variant="caption" color="primary" fontWeight="medium">
              <Icon fontSize="medium" style={{color: '#1876d2', fontSize: '1.1rem', marginLeft: '10px'}}>
                verified
              </Icon>
            </MDTypography>
          ) : (
            <MDTypography variant="caption" color="dark" fontWeight="medium"></MDTypography>
          ),
        commission: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.commission_rate}
          </MDTypography>
        ),
        prePay: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.pre_payment}
          </MDTypography>
        ),
        naver: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {
              <a href={item.link_url} target="_blank">
                {item.link_url}
              </a>
            }
          </MDTypography>
        ),
        holiday: (
          <MDTypography variant="caption" color="text" fontWeight="light">
            {item.is_members === 'Y' ? (
              <MDBox style={{display: 'flex'}}>
                <FormControlLabel control={<Checkbox onChange={handleCheckChange(idx, 1, week_holiday)} checked={week_holiday[1]} />} label="월" />
                <FormControlLabel control={<Checkbox onChange={handleCheckChange(idx, 2, week_holiday)} checked={week_holiday[2]} />} label="화" />
                <FormControlLabel control={<Checkbox onChange={handleCheckChange(idx, 3, week_holiday)} checked={week_holiday[3]} />} label="수" />
                <FormControlLabel control={<Checkbox onChange={handleCheckChange(idx, 4, week_holiday)} checked={week_holiday[4]} />} label="목" />
                <FormControlLabel control={<Checkbox onChange={handleCheckChange(idx, 5, week_holiday)} checked={week_holiday[5]} />} label="금" />
                <FormControlLabel control={<Checkbox onChange={handleCheckChange(idx, 6, week_holiday)} checked={week_holiday[6]} />} label="토" />
                <FormControlLabel control={<Checkbox onChange={handleCheckChange(idx, 0, week_holiday)} checked={week_holiday[0]} />} label="일" />
              </MDBox>
            ) : (
              <MDBox style={{height: '40px'}}></MDBox>
            )}
          </MDTypography>
        ),
      });
    });
    setRows(renderItem);
  };

  return (
    <DashboardLayout>
      <HeaderNavbar title="매장관리 (룸서비스)" />

      <MDBox pt={2}>
        {loading && (
          <MDBox className="spinner-view">
            <GridLoader color={'#222'} loading={true} size={8} />
          </MDBox>
        )}

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card className="full-height">
              {tableData && (
                <MDBox style={{overflowY: 'auto', height: '100vh', margin: '4px'}}>
                  <MDBox className="thover store-table">
                    <DataTable table={{columns, rows}} isSorted={false} entriesPerPage={false} showTotalEntries={false} noEndBorder />
                  </MDBox>
                  <MDBox pt={2} pb={2} sx={{display: 'flex', justifyContent: 'center', fontSize: '11px'}}>
                    <Pagination className="pnation" count={pageTotal} size="small" color="secondary" page={pageCurrentIdx} onChange={handlePageChange} />
                  </MDBox>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Store;
