import {api} from '../api';
import * as Utils from './utils';

////////// [ COMMON (USER, NOTI, DASHBOARD, ETC) ] ///////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
/**
 * 알림톡 전송
 * @param mTemplateCode 템플릿 코드
 * @param mRecipientNo 수신번호
 * @param mTemplateParameter 템플릿 치환 파라미터
 */
export const sendKakaoAlimTalk = (mTemplateCode, mRecipientNo, mTemplateParameter) => {
  return new Promise(function (resolve, reject) {
    const postParams = {
      templateCode: mTemplateCode,
      recipientNo: mRecipientNo,
      templateParameter: mTemplateParameter,
    };
    console.log('[sendKakaoAlimTalk] 알릭톡 전송');
    console.log(postParams);

    api
      .post('/v1/noti/kakao/alimtalk', postParams)
      .then(res => {
        const {data} = res.data;
        console.log(data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        console.log('[DONE] sendKakaoAlimTalk');
      });
  });
};

/**
 * 전체 부룸 가입자 조회
 */
export const getAllUserList = () => {
  return new Promise(function (resolve, reject) {
    console.log(`[getAllUserList]`);

    api
      .get(`/v1/user/list`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getAllUserList');
      });
  });
};

/**
 * 고객 감사 쿠폰 더하기
 * @param userId 사용자 uuid
 */
export const updateFreeCouponPlus = userId => {
  return new Promise(function (resolve, reject) {
    if (userId !== null) {
      api
        .get(`/v1/room/update/coupon/plus?uuid=${userId}`)
        .then(res => {
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] updateFreeCouponPlus');
        });
    } else {
      console.log('[updateFreeCouponPlus] userId NULL.');
    }
  });
};

/**
 * 고객 감사 쿠폰 빼기
 * @param userId 사용자 uuid
 */
export const updateFreeCouponMinus = userId => {
  return new Promise(function (resolve, reject) {
    if (userId !== null) {
      api
        .get(`/v1/room/update/coupon/minus?uuid=${userId}`)
        .then(res => {
          const {data} = res;
          resolve(data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
        .finally(() => {
          console.log('[DONE] updateFreeCouponMinus');
        });
    } else {
      console.log('[updateFreeCouponMinus] userId NULL.');
    }
  });
};

/**
 * 배달 완료 건수
 */
export const getDeliveryDoneCount = () => {
  return new Promise(function (resolve, reject) {
    console.log(`[getDeliveryDoneCount]`);

    api
      .get(`/v1/dashboard/delivery/done/count`)
      .then(res => {
        const {data} = res;
        resolve(data[0]);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getDeliveryDoneCount');
      });
  });
};

/**
 * 월별 배달 완료 건수
 */
export const getDeliveryDoneMonthCount = () => {
  return new Promise(function (resolve, reject) {
    console.log(`[getDeliveryDoneMonthCount]`);

    api
      .get(`/v1/dashboard/delivery/done/month/count`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getDeliveryDoneMonthCount');
      });
  });
};

/**
 * 일별 배달 완료 건수
 */
export const getDeliveryDoneDayCount = () => {
  return new Promise(function (resolve, reject) {
    console.log(`[getDeliveryDoneDayCount]`);

    api
      .get(`/v1/dashboard/delivery/done/day/count`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getDeliveryDoneDayCount');
      });
  });
};

/**
 * 전체 주문 내역 가져오기
 */
export const getOrderTotalList = () => {
  return new Promise(function (resolve, reject) {
    console.log(`[getOrderTotalList]`);

    api
      .get(`/v1/dashboard/order/total/list`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getOrderTotalList');
      });
  });
};

////////// [ ROOM SERVICE ] ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
/**
 * 룸서비스 주문리스트 (특정날짜)
 * @param mDate 조회날짜
 */
export const getRoomServiceOrderListByDate = mDate => {
  return new Promise(function (resolve, reject) {
    console.log(`[getRoomServiceOrderListByDate] ${mDate}`);

    api
      .get(`/v1/room/order/list?tDate=${mDate}`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getRoomServiceOrderListByDate');
      });
  });
};

/**
 * 룸서비스 POID STATE 갱신
 * @param poid POID
 * @param state 상태값
 */
export const updateRoomPoidStatus = (poid, state) => {
  return new Promise(function (resolve, reject) {
    console.log(`[updateRoomPoidStatus] ${poid} / ${state}`);

    api
      .get(`/v1/room/poid/update?poid=${poid}&state=${state}`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] updateRoomPoidStatus');
      });
  });
};

/**
 * 룸서비스 BOID STATE 갱신
 * @param boid BOID
 * @param state 상태값
 */
export const updateRoomBoidStatus = (boid, state) => {
  return new Promise(function (resolve, reject) {
    console.log(`[updateRoomBoidStatus] ${boid} / ${state}`);

    api
      .get(`/v1/room/boid/update?boid=${boid}&state=${state}`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] updateRoomBoidStatus');
      });
  });
};

/**
 * 룸서비스 SOID STATE 갱신
 * @param soid SOID
 * @param state 상태값
 */
export const updateRoomSoidStatus = (soid, state) => {
  return new Promise(function (resolve, reject) {
    console.log(`[updateRoomSoidStatus] ${soid} / ${state}`);

    api
      .get(`/v1/room/soid/update?soid=${soid}&state=${state}`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] updateRoomSoidStatus');
      });
  });
};

/**
 * 부분환불
 * @param mTid 카드 결제 TID
 * @param mOrderId 카드 취소 OrderId
 * @param mCcancelAmt 부분취소금액
 */
export const serverPayCancelPartial = (mTid, mOrderId, mCcancelAmt) => {
  return new Promise(function (resolve, reject) {
    console.log(`[serverPayCancelPartial] ${mTid} / ${mOrderId} / ${mCcancelAmt}`);
    const params = {
      tid: mTid,
      orderId: mOrderId,
      cancelAmt: mCcancelAmt,
    };
    api
      .post(`/v1/pay/serverPayCancel`, params)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] serverPayCancelPartial');
      });
  });
};

/**
 * 전체환불
 * @param mTid 카드 결제 TID
 * @param mOrderId 카드 취소 OrderId
 */
export const serverPayCancelTotal = (mTid, mOrderId) => {
  return new Promise(function (resolve, reject) {
    console.log(`[serverPayCancelTotal] ${mTid} / ${mOrderId}`);
    const params = {
      tid: mTid,
      orderId: mOrderId,
    };
    api
      .post(`/v1/pay/serverPayCancel`, params)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] serverPayCancelTotal');
      });
  });
};

/**
 * 이멀전시 수정
 * @param value 이멀전시 Y,N
 * @param zoneCode 구역코드
 */
export const updateEmergency = (value, zoneCode) => {
  return new Promise(function (resolve, reject) {
    console.log(`[updateEmergency] ${value} / ${zoneCode}`);
    const params = {
      value: value,
      zoneCode: zoneCode,
    };
    api
      .put(`/v1/zone/emergency/update`, params)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] updateEmergency');
      });
  });
};

/**
 * 하단 배너 모드 수정
 * @param value 하단 배너 값
 * @param zoneCode 구역코드
 */
export const updateBanner = (value, zoneCode) => {
  return new Promise(function (resolve, reject) {
    console.log(`[updateBanner] ${value} / ${zoneCode}`);
    const params = {
      value: value,
      zoneCode: zoneCode,
    };
    api
      .put(`/v1/zone/banner/update`, params)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] updateBanner');
      });
  });
};

/**
 * 배달 완료 시간 기록
 * @param mPoid POID
 */
export const updateDeliveryTimeDone = mPoid => {
  return new Promise(function (resolve, reject) {
    console.log(`[updateDeliveryTimeDone] ${mPoid}`);
    api
      .get(`/v1/room/poid/delivery/done?poid=${mPoid}`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] updateDeliveryTimeDone');
      });
  });
};

////////// [ PRODUCT ] /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
/**
 * 룸서비스 구역별상품 리스트
 * @param zoneCode zoneCode
 */
export const getRoomProductListByZoneCode = zoneCode => {
  return new Promise(function (resolve, reject) {
    console.log(`[getRoomProductListByZoneCode] ${zoneCode}`);

    api
      .get(`/v1/product/room/menu/list?zoneCode=${zoneCode}`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getRoomProductListByZoneCode');
      });
  });
};

/**
 * 룸서비스 메뉴 Show 상태 업데이트
 * @param product_id 스토어아이디
 * @param is_show 상태값 (Y,N)
 */
export const updateRoomProductShow = (product_id, is_show) => {
  return new Promise(function (resolve, reject) {
    console.log(`[updateRoomProductShow] ${product_id} / ${is_show}`);

    api
      .get(`/v1/product/room/show/update?product_id=${product_id}&is_show=${is_show}`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] updateRoomProductShow');
      });
  });
};

/**
 * 룸서비스 메뉴 주문 상태 업데이트
 * @param product_id 스토어아이디
 * @param is_order 상태값 (Y,N)
 */
export const updateRoomProductOrder = (product_id, is_order) => {
  return new Promise(function (resolve, reject) {
    console.log(`[updateRoomProductOrder] ${product_id} / ${is_order}`);

    api
      .get(`/v1/product/room/order/update?product_id=${product_id}&is_order=${is_order}`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] updateRoomProductOrder');
      });
  });
};

/**
 * 룸서비스 구역 정보 가져오기
 * @param zoneCode zoneCode
 */
export const getRoomZoneInfo = zoneCode => {
  return new Promise(function (resolve, reject) {
    console.log(`[getRoomZoneInfo] ${zoneCode}`);

    api
      .get(`/v1/zone/info?zoneCode=${zoneCode}`)
      .then(res => {
        const {data} = res;
        resolve(data[0]);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getRoomZoneInfo');
      });
  });
};

////////// [ STORE ] ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
/**
 * 룸서비스 스토어 리스트
 * @param zoneCode zoneCode
 */
export const getRoomStoreListByZoneCode = zoneCode => {
  return new Promise(function (resolve, reject) {
    console.log(`[getRoomStoreListByZoneCode] ${zoneCode}`);

    api
      .get(`/v1/store/room/list?zoneCode=${zoneCode}`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getRoomStoreListByZoneCode');
      });
  });
};

/**
 * 룸서비스 스토어 오픈 상태 업데이트
 * @param store_id 스토어아이디
 * @param is_open 상태값 (Y,N)
 */
export const updateRoomStoreOpen = (store_id, is_open) => {
  return new Promise(function (resolve, reject) {
    console.log(`[updateRoomStoreOpen] ${store_id} / ${is_open}`);

    api
      .get(`/v1/store/room/open/update?store_id=${store_id}&is_open=${is_open}`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] updateRoomStoreOpen');
      });
  });
};

/**
 * 룸서비스 스토어 휴무 정보 업데이트
 * @param store_id 스토어아이디
 * @param holiday 휴무일 (0~6)
 */
export const updateRoomStoreHoliday = (store_id, holiday) => {
  return new Promise(function (resolve, reject) {
    console.log(`[updateRoomStoreHoliday] ${store_id} / ${holiday}`);

    api
      .get(`/v1/store/room/holiday/update?store_id=${store_id}&holiday=${holiday}`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] updateRoomStoreHoliday');
      });
  });
};

////////// [ CONCIERGE ] ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
/**
 * 컨시어지 전체 주문리스트
 */
export const getConciergeOrderTotalList = () => {
  return new Promise(function (resolve, reject) {
    console.log(`[getConciergeOrderTotalList]`);

    api
      .get(`/v1/concierge/order/list`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] getConciergeOrderTotalList');
      });
  });
};

/**
 * 컨시어지 서비스일 업데이트
 * @param toid TOID
 * @param serviceDate 서비스일
 */
export const updateConciergeServiceDate = (toid, serviceDate) => {
  return new Promise(function (resolve, reject) {
    console.log(`[updateConciergeServiceDate] ${toid} / ${serviceDate}`);

    api
      .get(`/v1/concierge/service/date/update?toid=${toid}&service_date=${serviceDate}`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] updateConciergeServiceDate');
      });
  });
};

/**
 * 컨시어지 POID STATE 갱신
 * @param toid TOID(POID)
 * @param state 상태값
 */
export const updateConciergePoidStatus = (toid, state) => {
  return new Promise(function (resolve, reject) {
    console.log(`[updateConciergePoidStatus] ${toid} / ${state}`);

    api
      .get(`/v1/concierge/poid/update?toid=${toid}&state=${state}`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] updateConciergePoidStatus');
      });
  });
};

/**
 * 컨시어지 BOID STATE 갱신
 * @param boid BOID
 * @param state 상태값
 */
export const updateConciergeBoidStatus = (boid, state) => {
  return new Promise(function (resolve, reject) {
    console.log(`[updateConciergeBoidStatus] ${boid} / ${state}`);

    api
      .get(`/v1/concierge/boid/update?boid=${boid}&state=${state}`)
      .then(res => {
        const {data} = res;
        resolve(data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      })
      .finally(() => {
        console.log('[DONE] updateConciergePoidStatus');
      });
  });
};
