import * as React from 'react';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import CryptoJS from 'crypto-js';
import * as constants from './Constants';
import {
  APP_KEY_IS_LOGIN,
  CONCIERGE_STATE_10_NORMAL_RECEIPT,
  CONCIERGE_STATE_1_DONE_PAY,
  CONCIERGE_STATE_20_SCHEDULE_ING,
  CONCIERGE_STATE_30_SCHEDULE_DONE,
  CONCIERGE_STATE_40_SERVICE_DONE,
  CONCIERGE_UNDER_STATE_1_COMPLETE_REFUND,
  ROOM_SERVICE_STATE_6_COMPLETE_DELIVERY,
} from './Constants';

/**
 * 주어진 객체를 복사해서 새로운 객체를 만든다.
 * @param src
 */
export const deepCopy = src => {
  return JSON.parse(JSON.stringify(src));
};

/**
 * 현재 브라우저가 모바일 타입인지를 반환
 */
export const isMobile = () => {
  return /Mobi/i.test(window.navigator.userAgent);
};

/**
 * 주어진 문자열이 빈 값인지를 검사한다.
 * @param value
 * @returns {boolean}
 */
export const isBlank = value => {
  if (!value) {
    return true;
  }

  if (typeof value === 'string') {
    const trimmed = value.trim();
    if (trimmed.length === 0) {
      return true;
    }
  } else {
    throw Error(`value ${value}는 string 형식이어야 합니다.`);
  }

  return false;
};

/**
 * value가 빈 값인 경우, defaultValue를 반환한다.
 * @param value
 * @param defaultValue
 * @returns {*}
 */
export const defaultString = (value, defaultValue) => {
  if (isBlank(value)) {
    return defaultValue;
  }
  return value;
};

/**
 * 주어진 문자열에서 숫자만 남긴다.
 */
export const getNumbersOnly = src => {
  if (isBlank(src)) {
    return '';
  }
  const numbersOnly = src.replace(/[^\d]/g, '');
  return numbersOnly;
};

/**
 * 주어진 문자열이 빈 값이 아닌지를 검사한다. TODO: lodash 의 것을 사용한다.
 * @param value
 * @returns {boolean}
 */
export const isNotBlank = value => {
  return !isBlank(value);
};

/**
 * 주어진 객체(array, map, js object)가 빈 값인지를 검사한다.
 * @param obj
 */
export const isEmpty = obj => {
  if (!obj || obj === undefined) {
    return true;
  }

  if (Object.entries) {
    return Object.entries(obj).length === 0 && (obj.constructor === Object || obj.constructor === Array);
  } else if (Object.keys) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  } else {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }
};

export const isNotEmpty = obj => {
  return !isEmpty(obj);
};

/**
 * 개행문자를 <br/> 태그로 치환.
 */
export const nl2br = str => {
  if (isBlank(str)) {
    return <React.Fragment />;
  }
  const splitTags = str.split('\n').map((item, key) => {
    return (
      <React.Fragment key={key}>
        {item}
        <br />
      </React.Fragment>
    );
  });
  return <React.Fragment>{splitTags}</React.Fragment>;
};

/**
 * 개행문자를 <br/> 태그로 치환.
 */
export const nl2brHtml = str => {
  if (isBlank(str)) {
    return <React.Fragment />;
  }
  const splitTags = str.split('\n').map((item, key) => {
    return (
      <React.Fragment key={key}>
        <div dangerouslySetInnerHTML={{__html: item}} />
      </React.Fragment>
    );
  });
  return <React.Fragment>{splitTags}</React.Fragment>;
};

/**
 * 두 배열의 내용이 같은지를 체크
 * @param array1
 * @param array2
 * @returns {boolean|boolean|*}
 */
export const isSameArray = (array1, array2) => {
  if (!array1 && !array2) {
    return true;
  }
  if ((array1 && !array2) || (!array1 && array2)) {
    return false;
  }
  if (array1.length !== array2.length) {
    return false;
  }

  // 정렬 상태까지 비교가 필요하면 sort를 하지 말고,
  // 그냥 처음부터 하나씩 비교하다가 다른 값이 있을 경우 return 하는게 가장 빠르다.
  const s1 = [...array1].sort();
  const s2 = [...array2].sort();
  return s1.toString() === s2.toString();

  // return s1.every(function (element, index) {
  //     return element === s2[index];
  // });
};

/**
 * boolean 형식 state 값을 toggle 한다.
 * @param that
 * @param name
 * @returns {(function(): void)|*}
 */
export const toggleState = (that, name) => () => {
  const prevValue = that.state[name];
  that.setState({
    [name]: !prevValue,
  });
};

/**
 * state 값을 변경한다.
 * @param that
 * @param name
 * @param value
 * @returns {(function(*))|*}
 */
export const updateState = (that, name, value) => e => {
  if (typeof value === 'undefined') {
    value = e.target.value;
  }

  that.setState({
    [name]: value,
  });
};

// 한국 전화번호 정규식
const phoneNumberRegExp = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
/**
 * 전화번호 유효성을 검사한다.
 * @param phoneNumber
 * @returns {boolean}
 */
export const isValidPhoneNumber = phoneNumber => {
  return phoneNumberRegExp.test(phoneNumber);
};

/**
 * 주소설정 (영어, 숫자)
 * @param phoneNumber
 * @returns {boolean}
 */
export const isValidAddress = value => {
  const engNum = /^[a-zA-Z0-9]*$/;
  return engNum.test(value);
};

/**
 * 개발모드 판정
 * @param phoneNumber
 * @returns {boolean}
 */
export const isDebuggableEnv = () => {
  const host = window.location.host;
  const isDebuggableEnv =
    host.startsWith('beta-dev.') ||
    host.startsWith('local.') ||
    host.startsWith('localhost') ||
    host.startsWith('127.0.0.1') ||
    host.indexOf('.local') > 0 ||
    host.startsWith('local-');
  return isDebuggableEnv;
};

/**
 * 날짜 yy.mm.dd
 */
export const getOrderDateStr = dValue => {
  const date = new Date(dValue);
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear().toString().substring(2, 4);
  month = month >= 10 ? month : '0' + month;
  day = day >= 10 ? day : '0' + day;

  return year + '.' + month + '.' + day;
};

/**
 * 날짜 yyyy-mm
 */
export const getOrderMonthType = dValue => {
  const date = new Date(dValue);
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear().toString();
  month = month >= 10 ? month : '0' + month;
  day = day >= 10 ? day : '0' + day;

  return year + '-' + month;
};

/**
 * 날짜 mm/dd
 */
export const getChartDateDayType = dValue => {
  const date = new Date(dValue);
  let month = date.getMonth() + 1;
  let day = date.getDate();
  //day = day >= 10 ? day : '0' + day;

  return month + '/' + day;
};

/**
 * 날짜 yyyy-mm-dd
 */
export const getOrderDateStrType2 = dValue => {
  const date = new Date(dValue);
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear().toString();
  month = month >= 10 ? month : '0' + month;
  day = day >= 10 ? day : '0' + day;

  return year + '-' + month + '-' + day;
};

/**
 * 날짜&시간 yyyy-mm-dd hh:mm:ss
 */
export const getOrderDateStrType3 = tDate => {
  const date = new Date(tDate);
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();

  month = month >= 10 ? month : '0' + month;
  day = day >= 10 ? day : '0' + day;
  hour = hour >= 10 ? hour : '0' + hour;
  minute = minute >= 10 ? minute : '0' + minute;
  second = second >= 10 ? second : '0' + second;

  return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
};

/**
 * 날짜&시간 yyyy-mm-dd hh:mm:ss
 */
export const getNowTime = () => {
  const date = new Date();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();

  month = month >= 10 ? month : '0' + month;
  day = day >= 10 ? day : '0' + day;
  hour = hour >= 10 ? hour : '0' + hour;
  minute = minute >= 10 ? minute : '0' + minute;
  second = second >= 10 ? second : '0' + second;

  return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
};

/**
 * 날짜&시간 yyyy-mm-dd hh:mm:ss
 */
export const getNowTimeType2 = () => {
  const date = new Date();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();

  month = month >= 10 ? month : '0' + month;
  day = day >= 10 ? day : '0' + day;
  hour = hour >= 10 ? hour : '0' + hour;
  minute = minute >= 10 ? minute : '0' + minute;
  second = second >= 10 ? second : '0' + second;

  return date.getFullYear() + '-' + month + '-' + day;
};

/**
 * 시간 hh:mm
 */
export const getOrderTimeStr = dValue => {
  const date = new Date(dValue);
  let hour = date.getHours();
  hour = hour >= 10 ? hour : '0' + hour;
  let min = date.getMinutes();
  min = min >= 10 ? min : '0' + min;

  return hour + ':' + min;
};

/**
 * 시간 hh:mm
 */
export const getDayText = tDate => {
  console.log('[getDayText] ' + tDate);
  let week = new Array('일', '월', '화', '수', '목', '금', '토');
  let mDay = week[tDate.getDay()];

  return mDay + '요일';
};

/**
 * 날짜 yyyymmddhhmmss
 */
export const getDateTimeString = () => {
  const date = new Date();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();

  month = month >= 10 ? month : '0' + month;
  day = day >= 10 ? day : '0' + day;
  hour = hour >= 10 ? hour : '0' + hour;
  minute = minute >= 10 ? minute : '0' + minute;
  second = second >= 10 ? second : '0' + second;

  return date.getFullYear() + month + day + hour + minute + second;
};

/**
 * 원화 금액 3자리 콤마 표시함
 * @param price
 * @returns {String}
 */
export const wonPriceStr = price => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * 달력 시작일 : 이번달의 매월 1일 계산
 */
export const stMonthDay = () => {
  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth();

  return new Date(year, month, 1);
};

/**
 * 전화번호 하이픈 넣기
 */
export const phoneRex = phoneNum => {
  if (phoneNum) {
    return phoneNum.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }

  return phoneNum;
};

/**
 * 주문취소 환불 ORDER ID 생성
 */
export const getCancelPayOrderId = orderId => {
  return orderId + '_REFUND_' + getDateTimeString();
};

/**
 * [확인],[취소] 다이얼로그
 */
export const showDialog = (title, message) => {
  return new Promise((resolve, reject) => {
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: '확인',
          onClick: () => resolve(true),
        },
        {
          label: '취소',
          onClick: () => resolve(false),
        },
      ],
    });
  });
};

/**
 * [확인] 알림창
 */
export const showAlert = (title, message) => {
  return new Promise((resolve, reject) => {
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: '확인',
          onClick: () => resolve(true),
        },
      ],
    });
  });
};

/**
 * 전화번호 복호화
 */
export const getDecryptPhoneNumber = phone => {
  const rePhone = phone.replaceAll(' ', '+');
  const bytes = CryptoJS.AES.decrypt(rePhone, 'vooroomKey');
  const decryptPhone = bytes.toString(CryptoJS.enc.Utf8);

  return decryptPhone;
};

/**
 * 룸서비스 상태값 반환
 */
export const getRoomServiceStateStr = state => {
  console.log('util getRoomServiceStateStr : ' + state);
  let stateStr = null;
  switch (state) {
    case constants.ROOM_SERVICE_STATE_1_DONE_PAY:
      stateStr = '결제완료';
      break;
    case constants.ROOM_SERVICE_STATE_2_WAIT_ORDER:
      stateStr = '접수대기';
      break;
    case constants.ROOM_SERVICE_STATE_3_1_NORMAL_RECEIPT:
      stateStr = '정상접수';
      break;
    case constants.ROOM_SERVICE_STATE_3_2_DELAY_RECEIPT:
      stateStr = '지연접수';
      break;
    case constants.ROOM_SERVICE_STATE_4_DELAY_DELIVERY:
      stateStr = '배달지연';
      break;
    case constants.ROOM_SERVICE_STATE_5_COMPLETE_PICKUP:
      stateStr = '픽업완료';
      break;
    case constants.ROOM_SERVICE_STATE_6_COMPLETE_DELIVERY:
      stateStr = '배달완료';
      break;
    case constants.ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND:
      stateStr = '환불완료';
      break;
    case 100:
      stateStr = '(공구)접수대기';
      break;
    case 110:
      stateStr = '(공구)배송시작';
      break;
    case 120:
      stateStr = '(공구)배송완료';
      break;
    case -2:
      stateStr = '(공구)환불완료<';
      break;
    default:
      break;
  }
  return stateStr;
};

/**
 * 컨시어지 상태값 반환
 */
export const getConciergeServiceStateStr = state => {
  console.log('util getConciergeServiceStateStr : ' + state);
  let stateStr = null;
  switch (state) {
    case constants.CONCIERGE_STATE_1_DONE_PAY:
      stateStr = '결제완료';
      break;
    case constants.CONCIERGE_STATE_10_NORMAL_RECEIPT:
      stateStr = '접수완료';
      break;
    case constants.CONCIERGE_STATE_20_SCHEDULE_ING:
      stateStr = '일정조율';
      break;
    case constants.CONCIERGE_STATE_30_SCHEDULE_DONE:
      stateStr = '일정확인';
      break;
    case constants.CONCIERGE_STATE_40_SERVICE_DONE:
      stateStr = '시행완료';
      break;
    case constants.CONCIERGE_UNDER_STATE_1_COMPLETE_REFUND:
      stateStr = '환불완료';
      break;
    default:
      break;
  }
  return stateStr;
};

/**
 * 날짜 mm/dd hh:mm
 */
export const getOrderDateTimeString = tDate => {
  const date = new Date(tDate);
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();

  month = month >= 10 ? month : '0' + month;
  day = day >= 10 ? day : '0' + day;
  hour = hour >= 10 ? hour : '0' + hour;
  minute = minute >= 10 ? minute : '0' + minute;

  return month + '/' + day + ' ' + hour + ':' + minute;
};

/**
 * 상태 변경 박스 색상
 */
export const getSelectBoxColor = mState => {
  let result = '#ffffdc';
  switch (mState) {
    case constants.ROOM_SERVICE_STATE_1_DONE_PAY: // 결제완료
      result = '#FFFFFF';
      break;
    case constants.ROOM_SERVICE_STATE_6_COMPLETE_DELIVERY: // 배달완료
      result = '#EEEEEE';
      break;
    case constants.ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND: // 환불완료
      result = '#f7e3eb';
      break;
    case -2: // (공구)환불완료
      result = '#f7e3eb';
      break;
    case 100: // (공구)접수대기
      result = '#f3fbe9';
      break;
    case 110: // (공구)배송시작
      result = '#f3fbe9';
      break;
    case 120: // (공구)배송완료
      result = '#EEEEEE';
      break;
  }
  return result;
};

/**
 * 상태 변경 박스 색상
 */
export const getConciergeSelectBoxColor = mState => {
  let result = '#ffffdc';
  switch (mState) {
    case constants.CONCIERGE_STATE_1_DONE_PAY:
      result = '#ffffff';
      break;
    case constants.CONCIERGE_STATE_10_NORMAL_RECEIPT:
      result = '#ffffdc';
      break;
    case constants.CONCIERGE_STATE_20_SCHEDULE_ING:
      result = '#ffffdc';
      break;
    case constants.CONCIERGE_STATE_30_SCHEDULE_DONE:
      result = '#ffffdc';
      break;
    case constants.CONCIERGE_STATE_40_SERVICE_DONE:
      result = '#EEEEEE';
      break;
    case constants.CONCIERGE_UNDER_STATE_1_COMPLETE_REFUND: // 환불완료
      result = '#f7e3eb';
      break;
  }
  return result;
};

/**
 * 로그인 체크
 */
export const chkLogin = () => {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem(APP_KEY_IS_LOGIN) === 'true') {
      resolve(true);
    }
    resolve(false);
  });
};

/**
 * 시간차이 (분)
 */
export const diffTimeByMin = (dateA, dateB) => {
  const diffMSec = new Date(dateA).getTime() - new Date(dateB).getTime();
  const diffMin = Math.floor(diffMSec / (60 * 1000));

  return Math.abs(diffMin);
};

/**
 * get localStorage (Async)
 */
export const getLocalStorage = key => {
  const value = localStorage.getItem(key);
  //console.log(`[getLocalStorage] : ${key} / ${value}`);
  return value;
};

/**
 * get localStorage (Sync)
 */
export const getLocalStorageSync = key => {
  return new Promise((resolve, reject) => {
    const value = localStorage.getItem(key);
    console.log(`[getLocalStorageSync] : ${key} / ${value}`);
    resolve(value);
  });
};

/**
 * 컨시어지 타입 반환 (R/T)
 */
export const getConciergeType = mCode => {
  let result = '';
  switch (mCode) {
    case 'g1s35':
      result = 'R';
      break;
    case 'g1s37':
      result = 'T';
      break;
  }
  return result;
};
