import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as Utils from '../../../shared/utils';

function SimpleDialog({open, setOk, setCancel, title, body, mode}) {
  return (
    <div>
      <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent style={{minWidth: '500px'}}>
          <DialogContentText id="alert-dialog-description">{Utils.nl2br(body)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {mode !== 'alert' && <Button onClick={setCancel}>취소</Button>}
          <Button onClick={setOk} autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SimpleDialog;
