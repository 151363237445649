import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Button from '@mui/material/Button';
import 'dayjs/locale/ko';
import {navbar, navbarContainer} from './styles';
import {useMaterialUIController, setTransparentNavbar, setMiniSidenav, setOrderQueryDate, setQueryDateRoom, setHeaderNavbarButtonCmd} from 'context';
import * as Utils from '../../../shared/utils';
import '../../../assets/common.css';

function HeaderConciergeNavbar({absolute, light, isMini, title, mode}) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {miniSidenav, transparentNavbar, fixedNavbar, darkMode, queryDateRoom, roomServiceOrderData, conciergeOrderData} = controller;
  const [queryDateOfRoom, setQueryDateOfRoom] = useState(queryDateRoom);
  const [roomTodayDate, setRoomTodayDate] = useState('');
  const [roomTodayOrderCount, setRoomTodayOrderCount] = useState(0);
  const [roomTodayOrderPrice, setRoomTodayOrderPrice] = useState(0);
  const [roomTodayRefundCount, setRoomTodayRefundCount] = useState(0);
  const [roomTodayRefundPrice, setRoomTodayRefundPrice] = useState(0);
  const [autoRefesh, setAutoRefesh] = useState(false);
  const [autoRefeshCount, setAutoRefeshCount] = useState(9);
  const [intervalTimer, setIntervalTimer] = useState(null);
  const fileName = 'vooroom-roomservice_' + Utils.getNowTimeType2() + '.csv';

  useEffect(() => {}, []);

  useEffect(() => {
    setQueryDateRoom(dispatch, queryDateOfRoom);
  }, [queryDateOfRoom]);

  useEffect(() => {
    setRoomTodayOrderCount(roomServiceOrderData.tOrderCount);
    setRoomTodayOrderPrice(roomServiceOrderData.tOrderPrice);

    setRoomTodayRefundCount(roomServiceOrderData.tRefundCount);
    setRoomTodayRefundPrice(roomServiceOrderData.tRefundPrice);
  }, [roomServiceOrderData]);

  useEffect(() => {
    setRoomTodayDate(Utils.getOrderDateStrType2(queryDateRoom) + ' (' + Utils.getDayText(new Date(queryDateRoom)) + ')');
    setQueryDateOfRoom(queryDateRoom);
  }, [queryDateRoom]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    const handleTransparentNavbar = () => {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    };

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  // Styles for the navbar icons
  const iconsStyle = ({palette: {dark, white, text}, functions: {rgba}}) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={theme => navbar(theme, {transparentNavbar, absolute, light, darkMode})}
      style={{minHeight: '76px', maxHeight: '76px'}}>
      <Toolbar sx={theme => navbarContainer(theme)} style={{justifyContent: 'flex-start'}}>
        <div className="dashboard-bar">
          <div style={{display: 'flex', alignItems: 'center', minWidth: '220px'}}>
            <MDBox color={light ? 'white' : 'inherit'}>
              <IconButton size="small" disableRipple color="inherit" onClick={handleMiniSidenav}>
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
            </MDBox>
            <MDTypography
              variant="caption"
              color="text"
              fontWeight="medium"
              fontSize="medium"
              style={{
                textAlign: 'center',
                color: '#222',
                marginLeft: '6px',
                marginRight: '16px',
              }}>
              컨시어지
            </MDTypography>
          </div>
          <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
            <MDTypography variant="caption" color="dark" fontWeight="medium" className="flex-center" style={{marginRight: '24px'}}>
              <Icon fontSize="small" style={{marginRight: '4px'}}>
                add_shopping_cart
              </Icon>
              총 주문 {conciergeOrderData.tTotalPoid} 건 (POID)
            </MDTypography>
            <MDTypography variant="caption" color="dark" fontWeight="medium" className="flex-center" style={{marginRight: '24px'}}>
              <Icon fontSize="small" style={{marginRight: '4px'}}>
                local_atm
              </Icon>
              총 매출 {conciergeOrderData.tTotalOrderPrice && Utils.wonPriceStr(conciergeOrderData.tTotalOrderPrice)} 원
            </MDTypography>
            <Button
              variant="outlined"
              color="secondary"
              sx={{backgroundColor: '#f0f2f5', borderColor: '#d2d6da', color: '#495057', width: 102, height: 44.13, marginTop: 0.5, marginLeft: '16px'}}
              onClick={() => {
                setHeaderNavbarButtonCmd(dispatch, 'concierge_refesh');
              }}>
              새로고침
            </Button>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
HeaderConciergeNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  title: '',
  mode: '',
};

// Typechecking props for the DashboardNavbar
HeaderConciergeNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  title: PropTypes.string,
  mode: PropTypes.string,
};

export default HeaderConciergeNavbar;
