import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MDBox from 'components/MDBox';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import DashboardLayout from '../../../modules/LayoutContainers/DashboardLayout';
import HeaderNavbar from '../../../modules/Navbars/HeaderNavbar';
import * as Service from '../../../shared/apiService';
import * as Utils from '../../../shared/utils';
import * as Constants from '../../../shared/Constants';
import DataTable from '../../../modules/Tables/DataTable';
import MDTypography from '../../../components/MDTypography';
import Icon from '@mui/material/Icon';
import {useMaterialUIController, setQueryDateRoom, setHeaderNavbarButtonCmd, setRoomServiceOrderData, setMiniSidenav, setRoomZoneInfo, setRoomExcelData} from '../../../context';
import {GridLoader} from 'react-spinners';
import ringer from '../../../assets/sound/flipdish-ringer.mp3';
import '../../../assets/common.css';
import MDSnackbar from '../../../components/MDSnackbar';
import {APP_KEY_LOADING_ROOM_SERVICE, ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND, WEBSOCKET_SERVER_URL} from '../../../shared/Constants';
const audio = new Audio(ringer);
audio.loop = false;
const ws = new WebSocket(WEBSOCKET_SERVER_URL);

function RoomService() {
  const navigate = useNavigate();
  const columns = [
    /*{Header: '주문번호', accessor: 'poid', align: 'left', width: '90px'},*/
    {Header: '주문일시', accessor: 'orderDate', align: 'left', width: '90px'},
    {Header: '주소', accessor: 'addr', align: 'left'},
    {Header: '요청사항', accessor: 'reqMemo', align: 'left', width: '90px'},
    /*{Header: '고객ID', accessor: 'cNum', align: 'left', width: '90px'},*/
    {Header: '고객정보', accessor: 'cPhone', align: 'left', width: '90px'},
    {Header: '스토어', accessor: 'store', align: 'left'},
    /*{Header: '스토어 연락처', accessor: 'storePhone', align: 'left'},*/
    {Header: '상품', accessor: 'productName', align: 'left'},
    {Header: '수량', accessor: 'count', align: 'left', width: '90px'},
    {Header: '단가', accessor: 'price', align: 'left', width: '90px'},
    {Header: '결제금액', accessor: 'pay', align: 'left', width: '90px'},
    /*{Header: '쿠폰사용', accessor: 'coupon', align: 'left', width: '90px'},*/
    {Header: '시간(쿠폰)', accessor: 'total_time', align: 'left'},
    {Header: 'BOID 상태', accessor: 'boidCycle', align: 'left', width: '90px'},
    {Header: 'SOID 상태', accessor: 'soidCycle', align: 'left', width: '90px'},
    {Header: 'POID 상태', accessor: 'poidCycle', align: 'left', width: '90px'},
  ];

  // -- useEffect -----------------------------------------------------------------------
  const [controller, dispatch] = useMaterialUIController();
  const {queryDateRoom, headerNavbarButtonCmd, roomServiceOrderData} = controller;
  const [rows, setRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoding] = useState(true);
  const [orderInfo, setOrderInfo] = useState({});
  const [prevOrderCount, setPrevOrderCount] = useState(null);
  const [showSnack, setShowSnack] = useState(false);
  const [webSockData, setWebSockData] = useState(null);
  const [isAutoRefresh, setIsAutoRefresh] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const closeSnack = () => setShowSnack(false);

  useEffect(() => {
    // - 로그인 체크
    Utils.chkLogin().then(result => {
      if (result) {
        if (Utils.getOrderDateStrType2(queryDateRoom) !== Utils.getOrderDateStrType2(new Date())) {
          setQueryDateRoom(dispatch, new Date());
        }
      } else {
        navigate('/');
      }
    });

    // 최초로딩 체크 -> 새로고침  -> 홈이동
    Utils.getLocalStorageSync(APP_KEY_LOADING_ROOM_SERVICE).then(result => {
      if (result === 'true') {
        localStorage.setItem(APP_KEY_LOADING_ROOM_SERVICE, false);
        navigate('/main');
      } else {
        localStorage.setItem(APP_KEY_LOADING_ROOM_SERVICE, true);
      }
    });

    // 최소화
    setMiniSidenav(dispatch, true);

    // 구역정보 가져오기
    Service.getRoomZoneInfo('4_DT2_G1').then(result => {
      console.log('[구역정보]', result);

      const roomServiceZoneInfo = {
        zoneEmergency: result.is_emergency,
        zoneBanner: result.is_weather,
      };

      setRoomZoneInfo(dispatch, roomServiceZoneInfo);
    });

    // 웹소켓 오픈
    if (ws) {
      ws.onopen = () => {
        console.log('[webSocket] Connected.');
      };
      ws.onmessage = evt => {
        console.log(evt.data);
        console.log(`[webSocket] Received from Server : ${evt.data}`);
        setWebSockData(evt.data);
      };
    }

    // 2분간격 안전 타이머 가동
    /*setInterval(() => {
      console.log('[안전 타이머 실행] 새로고침!');
      refreshTableData();
    }, 120000);*/
  }, []);

  useEffect(() => {
    if (webSockData === 'newOrder') {
      console.log('신규 주문 발생 --> ' + Utils.getNowTime());
      // -TODO: 주문 노티 테스트중...
      if (!isAutoRefresh) {
        console.log('주문 정보 갱신 시작 -->');

        // 01 - 사운드 출력
        audio.play();
        Utils.showAlert('알림', '신규 주문이 도착하였습니다.');

        // 02 - 주문 목록 갱신
        refreshTableData();
      } else {
        console.log('isAutoRefresh is On.');
      }
      setWebSockData(null);
    }
  }, [webSockData]);

  useEffect(() => {
    console.log('결과값 저장');
    console.log('[orderInfo]', orderInfo);
    let tOrderInfo = orderInfo;
    setRoomServiceOrderData(dispatch, tOrderInfo);
  }, [orderInfo]);

  useEffect(() => {
    setLoding(true);
    setPrevOrderCount(null);
    getOrderData(Utils.getOrderDateStrType2(queryDateRoom));
  }, [queryDateRoom]);

  // 상단 콘트롤 버튼
  useEffect(() => {
    if (headerNavbarButtonCmd !== '') {
      console.log('[CMD] ' + headerNavbarButtonCmd);

      switch (headerNavbarButtonCmd) {
        case 'room_refesh':
          refreshTableData();
          break;
        case 'room_excel':
          break;
        case 'room_play':
          console.log('room_play');
          setIsAutoRefresh(true);
          break;
        case 'room_stop':
          console.log('room_stop');
          setIsAutoRefresh(false);
          break;
        case 'Y':
          Utils.showAlert('알림', '긴급모드(Emergency) 적용 되었습니다.').then(result => {
            Service.updateEmergency('Y', '4_DT2_G1');
          });
          break;
        case 'J':
          Utils.showAlert('알림', '기상 일시 지연이 적용 되었습니다.').then(result => {
            Service.updateEmergency('J', '4_DT2_G1');
          });
          break;
        case 'F':
          Utils.showAlert('알림', '주문 폭주 상태(주문 잠시 대기)가 적용 되었습니다.').then(result => {
            Service.updateEmergency('F', '4_DT2_G1');
          });
          break;
        case 'room_emergency_off':
          Utils.showAlert('알림', '긴급모드 & 임시휴무 중지 되었습니다.').then(result => {
            Service.updateEmergency('N', '4_DT2_G1');
          });
          break;
        case 'M':
          Utils.showAlert('알림', '임시휴무(내부사정 이슈) 적용 되었습니다.').then(result => {
            Service.updateEmergency('M', '4_DT2_G1');
          });
          break;
        case 'W':
          Utils.showAlert('알림', '임시휴무(기상악화 이슈) 적용 되었습니다.').then(result => {
            Service.updateEmergency('W', '4_DT2_G1');
          });
          break;
        case 'room_banner_off':
          Utils.showAlert('알림', '부룸앱 배너 상태 [OFF] 처리 되었습니다.').then(result => {
            Service.updateBanner('N', '4_DT2_G1');
          });
          break;
        case 'H':
          Utils.showAlert('알림', '부룸앱 배너 상태 [배달지연] 처리 되었습니다.').then(result => {
            Service.updateBanner('H', '4_DT2_G1');
          });
          break;
        case 'Z':
          Utils.showAlert('알림', '부룸앱 배너 상태 [기상지연] 처리 되었습니다.').then(result => {
            Service.updateBanner('Z', '4_DT2_G1');
          });
          break;
        case 'R':
          Utils.showAlert('알림', '부룸앱 배너 상태 [강우지연] 처리 되었습니다.').then(result => {
            Service.updateBanner('R', '4_DT2_G1');
          });
          break;
        case 'S':
          Utils.showAlert('알림', '부룸앱 배너 상태 [강설지연] 처리 되었습니다.').then(result => {
            Service.updateBanner('S', '4_DT2_G1');
          });
          break;
        case 'DR':
          Utils.showAlert('알림', '부룸앱 배너 상태 [강우중단] 처리 되었습니다.').then(result => {
            Service.updateBanner('DR', '4_DT2_G1');
          });
          break;
        case 'DS':
          Utils.showAlert('알림', '부룸앱 배너 상태 [강설중단] 처리 되었습니다.').then(result => {
            Service.updateBanner('DS', '4_DT2_G1');
          });
          break;
        case 'D':
          Utils.showAlert('알림', '부룸앱 배너 상태 [내부사정 임시휴무] 처리 되었습니다.').then(result => {
            Service.updateBanner('D', '4_DT2_G1');
          });
          break;
        default:
          break;
      }
    }
    setHeaderNavbarButtonCmd(dispatch, '');
  }, [headerNavbarButtonCmd]);

  useEffect(() => {
    renderTable();
  }, [tableData]);

  // -- feature -------------------------------------------------------------------------------

  // - 쿠폰 사용 여부 계산 (음식 가격 합산 === 카드 결제값 >> 쿠폰 사용함)
  const isCouponUse = (mPoid, payPrice) => {
    let sumPrice = 0;
    tableData.forEach((item, idx) => {
      const finalPrice = item.boid_price ? item.boid_price : item.price;
      if (item.poid === mPoid) {
        sumPrice += finalPrice * item.count;
      }
    });

    return payPrice === sumPrice ? true : false;
  };

  const refreshTableData = () => {
    getOrderData(Utils.getOrderDateStrType2(queryDateRoom));
  };

  const getOrderData = mDate => {
    if (mDate) {
      Service.getRoomServiceOrderListByDate(mDate).then(async result => {
        console.log(result);
        setTableData(result);

        // 주문 통계 정보 처리
        let prePoid = '';
        let orderCount = 0;
        let orderPay = 0;
        let storePay = 0;
        let deliveryPay = 0;

        let preRefundPoid = '';
        let refundCount = 0;
        let refundPay = 0;
        result.forEach((item, idx) => {
          // ------------------------------------------
          // 주문&매출 건수 (POID)
          if (prePoid !== item.poid) {
            if (item.poid_state > 0) {
              orderCount++;
            }
            orderPay += item.pay_price;
            prePoid = item.poid;
          }

          // ------------------------------------------
          const finalPrice = item.boid_price ? item.boid_price : item.price;

          // 환불건수 계산
          if (item.boid_state < 0) {
            refundCount++;
          }
          // 환불금액 계산
          if (item.poid_state < 0) {
            if (preRefundPoid !== item.poid) {
              refundPay += item.pay_price;
              preRefundPoid = item.poid;
            }
          } else if (item.boid_state < 0) {
            refundPay += finalPrice * item.count;
          }

          // 스토어 매출 계산
          if (item.boid_state > 0) {
            storePay += finalPrice * item.count;
          }
        });

        // 결과값 저장
        setOrderInfo({
          tOrderCount: orderCount,
          tOrderPrice: orderPay - refundPay,
          tOrderStorePrice: storePay,
          tOrderDeliveryPrice: orderPay - refundPay - storePay,
          tRefundCount: refundCount,
          tRefundPrice: refundPay,
        });

        // 사운드 재생 판단 --> 자동 갱신 모드일때만..
        if (isAutoRefresh) {
          if (prevOrderCount === null) {
            setPrevOrderCount(orderCount);
          } else if (orderCount > prevOrderCount) {
            await audio.play();
            Utils.showAlert('알림', '신규 주문이 도착하였습니다.');
            setPrevOrderCount(orderCount);
          }
        }
      });
    } else {
      console.log('[getOrderData] mDate Null.');
    }
  };

  // - 부분환불 처리
  const serverPayCancelPartial = (tid, orderId, amount, soid, boid, state, idx) => {
    return new Promise(function (resolve, reject) {
      const cancelOrderId = Utils.getCancelPayOrderId(orderId);
      Service.serverPayCancelPartial(tid, cancelOrderId, amount).then(result => {
        console.log(result);
        if (result.resultCode === '0000') {
          // - SOID 환불 성공
          if (soid) {
            Service.updateRoomSoidStatus(soid, state).then(result => {
              console.log(result);
              setTableData([]);

              let mTableData = tableData;
              mTableData[idx].soid_state = state;
              setTableData(mTableData);
            });

            // SOID에 속한 BOID 상태 변경
            procRefundUnderSoid(soid);
            Utils.showAlert('성공', '부분 환불 처리 성공하였습니다. (SOID)');
          }

          // - BOID 환불 성공
          if (boid) {
            Service.updateRoomBoidStatus(boid, state).then(result => {
              console.log(result);
              setTableData([]);

              let mTableData = tableData;
              mTableData[idx].boid_state = state;
              setTableData(mTableData);
              Utils.showAlert('성공', '부분 환불 처리 성공하였습니다. (BOID)');
            });
          }

          // - 새로고침
          setTableData([]);
          refreshTableData();
          resolve(true);
        } else {
          // 환불 처리 실패
          Utils.showAlert('실패', '[ ' + result.resultCode + ' ] ' + result.resultMsg);
          resolve(false);
        }
      });
    });
  };

  // - 전체환불 처리
  const serverPayCancelTotal = (tid, orderId, poid, state, idx) => {
    return new Promise(function (resolve, reject) {
      const cancelOrderId = Utils.getCancelPayOrderId(orderId);
      Service.serverPayCancelTotal(tid, cancelOrderId).then(result => {
        console.log(result);
        if (result.resultCode === '0000') {
          Service.updateRoomPoidStatus(poid, state).then(result => {
            console.log(result);
            setTableData([]);

            let mTableData = tableData;
            mTableData[idx].poid_state = state;
            setTableData(mTableData);
          });

          // POID에 속한 SOID, BOID 상태 변경
          procUpdateStateUnderPoid(poid, state);
          Utils.showAlert('성공', '전체 환불 처리 성공하였습니다. (POID)');

          // - 새로고침
          setTableData([]);
          refreshTableData();

          resolve(true);
        } else {
          // 환불 처리 실패
          resolve(false);
          Utils.showAlert('실패', '[ ' + result.resultCode + ' ] ' + result.resultMsg);
        }
      });
    });
  };

  // - 스토어별(SOID) 환불 금액 계산
  const calcRefundPriceBySoid = mSoid => {
    let refundPrice = 0;

    tableData.forEach((item, idx) => {
      const finalPrice = item.boid_price ? item.boid_price : item.price;
      if (item.soid === mSoid) {
        refundPrice += finalPrice * item.count;
      }
    });

    console.log('[calcRefundPriceBySoid] ' + refundPrice);
    return refundPrice;
  };

  // - POID 환불시 하위 SOID, BOID 상태값 동기화.
  const procUpdateStateUnderPoid = (mPoid, mState) => {
    let mTableData = tableData;
    setTableData([]);
    mTableData.forEach((item, idx) => {
      if (item.poid === mPoid) {
        mTableData[idx].poid_state = mState;

        if (mTableData[idx].soid_state !== Constants.ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND) {
          mTableData[idx].soid_state = mState;
          Service.updateRoomSoidStatus(item.soid, mState).then(result => {
            console.log('[procUpdateStateUnderPoid > Soid] soid : ' + item.soid);
          });
        }

        if (mTableData[idx].boid_state !== Constants.ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND) {
          mTableData[idx].boid_state = mState;
          Service.updateRoomBoidStatus(item.boid, mState).then(result => {
            console.log('[procUpdateStateUnderPoid > Boid] boid : ' + item.boid);
          });
        }
      }
    });
    setTableData(mTableData);
  };

  // - SOID 환불시 하위 BOID 상태값 동기화.
  const procRefundUnderSoid = mSoid => {
    let mTableData = tableData;
    mTableData.forEach((item, idx) => {
      if (item.soid === mSoid) {
        mTableData[idx].soid_state = Constants.ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND;

        if (mTableData[idx].boid_state !== Constants.ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND) {
          mTableData[idx].boid_state = Constants.ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND;
          Service.updateRoomBoidStatus(item.boid, Constants.ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND).then(result => {
            console.log('[procRefundUnder-Boid] boid : ' + item.boid);
          });
        }
      }
    });
    setTableData(mTableData);
  };

  // - POID 묶음주문에 대해서 대표 메뉴를 선정해 반환.
  const getPoidMenuName = mPoid => {
    let mPrice = 0;
    let mainMenuName = '';
    let mCount = 0;
    tableData.forEach((item, idx) => {
      // 단품 가격이 가장 비싼 메뉴를 대표 메뉴로 선정함.
      if (item.poid === mPoid) {
        // 환불된 메뉴는 제외
        if (item.boid_state !== Constants.ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND) {
          const finalPrice = item.boid_price ? item.boid_price : item.price;
          if (finalPrice > mPrice) {
            mPrice = finalPrice;
            mainMenuName = item.product_name;
          }
          mCount++;
        }
      }
    });

    return mCount > 1 ? mainMenuName + ' 외 ' + (mCount - 1) : mainMenuName;
  };

  // - 매니저 카톡 주문 텍스트
  const getManagerOrderCommand = mPoid => {
    let mTableData = tableData;
    let result = '-------------------------------------\n';
    let reqBell = false;
    let addrText = null;
    mTableData.forEach((item, idx) => {
      if (item.poid === mPoid) {
        // - 현관문밸
        if (!reqBell && item.request_bell === 'N') {
          reqBell = true;
          result += '[ 세대현관문 초인종 X ]\n\n';
        }

        // - 주소
        if (!addrText) {
          const address = item.room_id && item.room_id.split('/');
          addrText = '*' + address[0] + ' ' + address[1] + '동 ' + address[2] + '호';
          result += addrText;
        }

        // - 메뉴 (환불 메뉴는 제외)
        if (item.boid_state !== Constants.ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND) {
          result += '\n\n' + '[' + item.store_name + '] / ' + item.product_name + ' / ' + item.count + '개';
        }
      }
    });
    result += '\n-------------------------------------\n\n\n';

    return result;
  };

  // -- handler -------------------------------------------------------------------------------
  const handleSoidChange = (item, idx, state) => {
    const prevState = tableData[idx].soid_state;

    if (prevState > 60 || prevState < 0) {
      console.log('(공구) 사용불가');
      return;
    }

    if (prevState === Constants.ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND) {
      Utils.showAlert('알림', '이미 환불 처리된 상태에서는 수정이 불가합니다.');
    } else {
      // - 알림톡 파라미터 데이터 모음
      const dateTime = Utils.getOrderDateTimeString(item.create_date);
      const poid = item.poid;
      const soid = item.soid;
      const storeName = item.store_name;

      if (state < 0) {
        // - SOID 부분 주문 취소 (부분환불)
        let refundPrice = 0;
        let menuName = '';
        tableData.forEach((mItem, idx) => {
          if (mItem.soid === soid) {
            const finalPrice = mItem.boid_price ? mItem.boid_price : mItem.price;
            refundPrice += finalPrice * mItem.count;
            if (menuName.length > 0) menuName += ', ';
            menuName += mItem.product_name;
          }
        });
        const mCcancelAmt = refundPrice;
        //const mCcancelAmt = calcRefundPriceBySoid(item.soid);

        serverPayCancelPartial(item.tid, item.order_id, mCcancelAmt, item.soid, null, state, idx).then(result => {
          if (result) {
            // 스토어 부분 환불 성공 -> 알림톡 전송 (SOID)
            const mTemplateCode = 'r-s-refund_charge';
            const mRecipientNo = item.phone ? Utils.getDecryptPhoneNumber(item.phone) : '';
            const mTemplateParameter = {
              dateTime: dateTime,
              soid: poid,
              storeName: storeName,
              refundCharge: Utils.wonPriceStr(mCcancelAmt) + '원',
              menuName: menuName,
            };
            Service.sendKakaoAlimTalk(mTemplateCode, mRecipientNo, mTemplateParameter).then();
          }
        });
      } else {
        Service.updateRoomSoidStatus(item.soid, state).then(result => {
          console.log(result);
          setTableData([]);

          let mTableData = tableData;
          mTableData[idx].soid_state = state;
          setTableData(mTableData);
        });
      }
    }
  };

  const handleBoidChange = (item, idx, state) => {
    const prevState = tableData[idx].boid_state;

    if (prevState < 0) {
      Utils.showAlert('알림', '이미 환불 처리된 상태에서는 수정이 불가합니다.');
    } else {
      // - 알림톡 파라미터 데이터 모음
      const dateTime = Utils.getOrderDateTimeString(item.create_date);
      const poid = item.poid;
      const menuName = item.product_name;
      const storeName = item.store_name;

      if (state < 0) {
        // - BOID 부분 주문 취소 (부분환불)
        const finalPrice = item.boid_price ? item.boid_price : item.price;
        const mCcancelAmt = finalPrice * item.count;
        serverPayCancelPartial(item.tid, item.order_id, mCcancelAmt, null, item.boid, state, idx).then(result => {
          if (result) {
            // 메뉴별 부분 환불 성공 -> 알림톡 전송 (BOID)
            const mTemplateCode = state === Constants.ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND ? 'r-b-refund_charge' : 'c-b-refund_charge';
            const mRecipientNo = item.phone ? Utils.getDecryptPhoneNumber(item.phone) : '';
            const mTemplateParameter = {
              dateTime: dateTime,
              poid: poid,
              storeName: storeName,
              refundCharge: Utils.wonPriceStr(mCcancelAmt) + '원',
              menuName: menuName,
            };
            Service.sendKakaoAlimTalk(mTemplateCode, mRecipientNo, mTemplateParameter).then();
          }
        });
      } else {
        // - 일반 BOID 상태 변경
        Service.updateRoomBoidStatus(item.boid, state).then(result => {
          console.log(result);
          setTableData([]);
          let mTableData = tableData;
          mTableData[idx].boid_state = state;
          setTableData(mTableData);
        });
      }
    }
  };

  const handlePoidChange = (item, idx, state) => {
    const prevState = tableData[idx].poid_state;

    console.log('roomServiceOrderData', roomServiceOrderData);

    if (prevState < 0) {
      Utils.showAlert('알림', '이미 환불 처리된 상태에서는 수정이 불가합니다.');
    } else {
      // - 알림톡 파라미터 데이터 모음
      const dateTime = Utils.getOrderDateTimeString(item.create_date);
      const boid = item.boid;
      const poid = item.poid;
      const soid = item.soid;
      const payPrice = Utils.wonPriceStr(item.pay_price);
      const finalPrice = item.boid_price ? item.boid_price : item.price;
      const refundPrice = Utils.wonPriceStr(finalPrice * item.count);
      const menuName = getPoidMenuName(poid);
      const storeName = item.store_name;

      // - 음수 : 환불 및 취소, 양수 : 정상 O_Cycle
      if (state < 0) {
        // POID 전체 주문 취소 (전체환불)
        serverPayCancelTotal(item.tid, item.order_id, item.poid, state, idx).then(result => {
          if (result) {
            if (state === ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND) {
              // 전체 환불 알림톡 전송 (POID)
              const mTemplateCode = 'r-p-order_refusal';
              const mRecipientNo = item.phone ? Utils.getDecryptPhoneNumber(item.phone) : '';
              const mTemplateParameter = {
                dateTime: dateTime,
                poid: poid,
                menuName: menuName,
              };
              Service.sendKakaoAlimTalk(mTemplateCode, mRecipientNo, mTemplateParameter).then();

              // 쿠폰 사용 고객인 경우 감사 쿠폰 플러스 (+1)
              const isCoupon = isCouponUse(item.poid, item.pay_price);
              console.log('[쿠폰 사용 여부] ' + isCoupon);
              if (isCoupon) {
                Service.updateFreeCouponPlus(item.user_id).then();
                console.log('쿠폰 원복 + 1');
              }
            } else {
              // 공동구매 전체 환불 알림톡 전송 (POID)(-2)
              const mTemplateCode = 'c-p-order_refusal';
              const mRecipientNo = item.phone ? Utils.getDecryptPhoneNumber(item.phone) : '';
              const mTemplateParameter = {
                dateTime: dateTime,
                poid: poid,
                refundCharge: payPrice,
                menuName: menuName,
              };
              Service.sendKakaoAlimTalk(mTemplateCode, mRecipientNo, mTemplateParameter).then();
            }
          }
        });
      } else {
        // 일반 POID 상태 변경
        Service.updateRoomPoidStatus(item.poid, state).then(result => {
          // POID에 속한 SOID, BOID 상태 변경
          procUpdateStateUnderPoid(poid, state);

          // 배달완료 시간 기록
          if (state === Constants.ROOM_SERVICE_STATE_6_COMPLETE_DELIVERY) {
            Service.updateDeliveryTimeDone(item.poid).then();
          }

          // 고객 알림톡(카톡) 발송
          const notiType = Utils.getRoomServiceStateStr(state);
          if (notiType && state > 1) {
            Utils.showDialog('알림', '고객님께 [ ' + notiType + ' ] 알림톡을 전송 하시겠습니까?').then(result => {
              if (result) {
                let mTemplateCode = null;
                let mRecipientNo = item.phone ? Utils.getDecryptPhoneNumber(item.phone) : '';
                let mTemplateParameter = null;

                switch (state) {
                  case 10: // 접수대기
                    mTemplateCode = 'r-p-order_wait';
                    mTemplateParameter = {
                      dateTime: dateTime,
                      poid: poid,
                      menuName: menuName,
                    };
                    break;
                  case 20: // 정상접수
                    mTemplateCode = 'r-p-order_done';
                    mTemplateParameter = {
                      dateTime: dateTime,
                      poid: poid,
                      menuName: menuName,
                    };
                    break;
                  case 30: // 지연접수
                    mTemplateCode = 'r-p-order_delay_done';
                    mTemplateParameter = {
                      dateTime: dateTime,
                      poid: poid,
                      menuName: menuName,
                    };
                    break;
                  case 40: // 배달지연
                    mTemplateCode = 'r-p-delivery_delay';
                    mTemplateParameter = {
                      dateTime: dateTime,
                      poid: poid,
                      menuName: menuName,
                    };
                    break;
                  case 50: // 픽업완료
                    mTemplateCode = 'r-p-pickup_done';
                    mTemplateParameter = {
                      dateTime: dateTime,
                      poid: poid,
                      menuName: menuName,
                    };
                    break;
                  case 60: // 배달완료
                    mTemplateCode = 'r-p-delivery_done';
                    mTemplateParameter = {
                      dateTime: dateTime,
                      poid: poid,
                      menuName: menuName,
                    };
                    break;
                  case 100: // (공구) 접수대기
                    mTemplateCode = 'c-p-order_done';
                    mTemplateParameter = {
                      dateTime: dateTime,
                      poid: poid,
                      menuName: menuName,
                    };
                    break;
                  case 110: // (공구) 배송시작
                    mTemplateCode = 'c-p-delivery_start';
                    mTemplateParameter = {
                      dateTime: dateTime,
                      poid: poid,
                      menuName: menuName,
                    };
                    break;
                  case 120: // (공구) 배송완료
                    mTemplateCode = 'c-p-delivery_done';
                    mTemplateParameter = {
                      dateTime: dateTime,
                      poid: poid,
                      menuName: menuName,
                    };
                    break;
                  default:
                    break;
                }
                if (mTemplateCode && mRecipientNo && mTemplateParameter) {
                  Service.sendKakaoAlimTalk(mTemplateCode, mRecipientNo, mTemplateParameter).then();
                } else {
                  Utils.showAlert('실패', '알림톡 전송에 실패 하였습니다. (파라미터 오류)');
                  console.log('handlePoidChange', 'sendKakaoAlimTalk params null.');
                }
              }
            });
          } else {
            console.log('handlePoidChange', 'notiType is null / state 1');
          }
        });
      }
    }
  };

  // -- render -------------------------------------------------------------------------------
  const renderSnack = <MDSnackbar icon="notifications" title="부룸오피스" content="매니저 배달 요청 주문서 복사 완료" open={showSnack} onClose={closeSnack} close={closeSnack} />;

  const renderTable = () => {
    const renderItem = [];
    const excelItem = [];
    let excelHeader = ['POID', '주문일시', '주소', '고객ID', '고객명', '연락처', '스토어', '상품', '수량', '단가', '결제금액', '소요시간', '쿠폰', '배달상태'];
    excelItem.push(excelHeader);

    let prevPoid = '';
    let prevSoid = '';
    tableData.forEach((item, idx) => {
      // - 동호수 주소
      let address;
      if (item.room_id) {
        address = item.room_id.split('/');
      } else {
        address = 'N/A(탈퇴)';
      }

      // 주문번호
      const dateTime = Utils.getOrderDateStrType3(item.create_date).split(' ');

      // - 휴대폰 번호 복호화 처리
      let parsePhoneNum = item.phone ? Utils.getDecryptPhoneNumber(item.phone) : 'N/A(탈퇴)';

      // - 스토어 링크 주소
      let store_link_url = item.region_code ? 'https://vooroom-store.kr/' + item.region_code.toLowerCase() + '/' + item.store_code : null;

      // - 쿠폰 사용 여부
      let useCoupon = isCouponUse(item.poid, item.pay_price) ? 'O' : '';

      // - 소요시간 체크
      let totalTime = item.delivery_done_date ? Utils.diffTimeByMin(item.delivery_done_date, item.create_date) : null;

      // - 상품 가격
      const finalPrice = item.boid_price ? item.boid_price : item.price;

      // - 엑셀조합
      const d0 = prevPoid !== item.poid ? item.poid : '';
      const d1 = dateTime[0] + ' ' + dateTime[1];
      const d2 = address[0] + ' ' + address[1] + '동 ' + address[2] + '호';
      const d3 = item.user_id;
      const d4 = item.real_name;
      const d5 = item.phone && Utils.phoneRex(Utils.getDecryptPhoneNumber(item.phone));

      const d6 = item.store_name;
      const d7 = item.product_name;
      const d8 = item.count;
      const d9 = Utils.wonPriceStr(finalPrice);
      const d10 = Utils.wonPriceStr(item.pay_price);
      const d11 = totalTime + '분';
      const d12 = useCoupon;
      let tempStateStr = Utils.getRoomServiceStateStr(item.boid_state);
      const d13 = tempStateStr === '환불완료' ? '환불' : tempStateStr;

      const tempExcel = [d0, d1, d2, d3, d4, d5, d6, d7, d8, d9, d10, d11, d12, d13];
      excelItem.push(tempExcel);

      renderItem.push({
        /*poid: (
          <MDTypography variant="caption" color="dark" fontWeight="light" style={{fontSize: '11.8px'}}>
            {prevPoid !== item.poid && item.poid}
          </MDTypography>
        ),*/
        orderDate: prevPoid !== item.poid && (
          <MDTypography variant="caption" color="dark" fontWeight="medium">
            <span style={{fontSize: '12px', fontWeight: '300'}}>{prevPoid !== item.poid && '(' + item.poid + ')'}</span>
            <br />
            {dateTime[0]}
            <br />
            {dateTime[1]}
          </MDTypography>
        ),
        addr:
          prevPoid !== item.poid &&
          (address !== 'N/A(탈퇴)' ? (
            <CopyToClipboard
              onCopy={() => {
                setShowSnack(true);
              }}
              text={getManagerOrderCommand(item.poid)}>
              <MDTypography variant="caption" color="dark" fontWeight="medium">
                {address[0]}
                <br />
                {address[1]}동 {address[2]}호
                <Icon fontSize="medium" style={{marginLeft: '4px'}}>
                  content_copy
                </Icon>
              </MDTypography>
            </CopyToClipboard>
          ) : (
            <MDTypography variant="caption" color="dark" fontWeight="medium">
              {address}
            </MDTypography>
          )),
        reqMemo: prevPoid !== item.poid && (
          <MDTypography variant="caption" color="dark" fontWeight="medium">
            {item.request_bell === 'N' && <>초인종X</>}
          </MDTypography>
        ),
        /*cNum: (
          <MDTypography variant="caption" color="dark" fontWeight="light">
            {prevPoid !== item.poid && item.user_id}
          </MDTypography>
        ),*/
        cPhone: (
          <MDTypography variant="caption" color="dark" fontWeight="light">
            {prevPoid !== item.poid && '(' + item.user_id + ')'}
            <br />
            {prevPoid !== item.poid && parsePhoneNum && Utils.phoneRex(parsePhoneNum)}
            <br />
            {prevPoid !== item.poid && item.real_name && '(' + item.real_name + ')'}
          </MDTypography>
        ),
        store: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {prevSoid !== item.soid && (
              <>
                <a href={store_link_url} target="_blank">
                  {item.store_name}
                </a>
                <br />
                <span style={{fontSize: '12px'}}>{item.stair && item.cooking_time && '(' + item.stair + '층, ' + item.cooking_time + '분)'}</span>
              </>
            )}
          </MDTypography>
        ),
        /*storePhone: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {prevSoid !== item.soid && item.tel}
          </MDTypography>
        ),*/
        productName:
          item.poid_state === 1 ? (
            <MDTypography variant="caption" color="primary" fontWeight="medium">
              {item.product_name}
            </MDTypography>
          ) : (
            <MDTypography variant="caption" color="dark" fontWeight="medium">
              {item.product_name}
            </MDTypography>
          ),
        count:
          item.poid_state === 1 ? (
            <MDTypography variant="caption" color="primary" fontWeight="medium">
              {item.count}
            </MDTypography>
          ) : (
            <MDTypography variant="caption" color="dark" fontWeight="medium">
              {item.count}
            </MDTypography>
          ),
        price: (
          <MDTypography variant="caption" color="dark" fontWeight="light">
            {Utils.wonPriceStr(finalPrice)}
          </MDTypography>
        ),
        pay:
          item.poid_state < 0 ? (
            <MDTypography variant="caption" color="dark" fontWeight="light">
              {prevPoid !== item.poid && Utils.wonPriceStr(item.pay_price)}
            </MDTypography>
          ) : (
            <MDTypography variant="caption" color="dark" fontWeight="medium">
              {prevPoid !== item.poid && Utils.wonPriceStr(item.pay_price)}
            </MDTypography>
          ),
        /*coupon: (
          <MDTypography variant="caption" color="dark" fontWeight="bold">
            {prevPoid !== item.poid && useCoupon}
          </MDTypography>
        ),*/
        total_time:
          prevPoid !== item.poid && item.poid_state !== Constants.ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND && totalTime ? (
            <MDTypography variant="caption" color="dark" fontWeight="bold">
              {totalTime < 50 ? (
                <MDBox>
                  {totalTime + '분'}
                  {prevPoid !== item.poid && useCoupon !== '' && '(쿠폰)'}
                </MDBox>
              ) : (
                <MDBox style={{color: '#e91e63'}}>
                  {totalTime + '분'}
                  {prevPoid !== item.poid && useCoupon !== '' && '(쿠폰)'}
                </MDBox>
              )}
            </MDTypography>
          ) : (
            <MDTypography variant="caption" color="dark" fontWeight="bold">
              {prevPoid !== item.poid && totalTime && useCoupon !== '' && '(쿠폰)'}
            </MDTypography>
          ),
        boidCycle: (
          <FormControl sx={{m: 1, minWidth: 98, maxWidth: 98, padding: 0, margin: 0}} size="small">
            <Select
              labelId="demo-select-small"
              value={item.boid_state}
              sx={{
                fontSize: '18px',
                fontWeight: '600',
                backgroundColor: Utils.getSelectBoxColor(item.boid_state),
              }}
              onChange={e => {
                const state = e.target.value;
                if (state < 0) {
                  Utils.showDialog('알림', '부분 환불 처리 진행하시겠습니까? (BOID)').then(result => {
                    result && handleBoidChange(item, idx, state);
                  });
                } else {
                  handleBoidChange(item, idx, state);
                }
              }}>
              <MenuItem value={1}>(1) 결제완료</MenuItem>
              <MenuItem value={10}>(2) 접수대기</MenuItem>
              <MenuItem value={20}>(3-1) 정상접수</MenuItem>
              <MenuItem value={30}>(3-2) 지연접수</MenuItem>
              <MenuItem value={40}>(4) 배달지연</MenuItem>
              <MenuItem value={50}>(5) 픽업완료</MenuItem>
              <MenuItem value={60}>(6) 배달완료</MenuItem>
              <MenuItem value={0}>===========</MenuItem>
              <MenuItem value={-1}>(-1) 환불완료</MenuItem>
              <MenuItem value={0}>===========</MenuItem>
              <MenuItem value={100}>(공구)접수대기</MenuItem>
              <MenuItem value={110}>(공구)배송시작</MenuItem>
              <MenuItem value={120}>(공구)배송완료</MenuItem>
              <MenuItem value={-2}>(공구)환불완료</MenuItem>
            </Select>
          </FormControl>
        ),
        soidCycle: prevSoid !== item.soid && (
          <FormControl sx={{m: 1, minWidth: 98, maxWidth: 98, padding: 0, margin: 0}} size="small">
            <Select
              labelId="demo-select-small"
              value={item.soid_state}
              sx={{
                fontSize: '18px',
                fontWeight: '600',
                backgroundColor: Utils.getSelectBoxColor(item.soid_state),
              }}
              onChange={e => {
                const state = e.target.value;
                if (state < 0) {
                  Utils.showDialog('알림', '부분 환불 처리 진행하시겠습니까? (SOID)').then(result => {
                    result && handleSoidChange(item, idx, state);
                  });
                } else {
                  handleSoidChange(item, idx, state);
                }
              }}>
              <MenuItem value={1}>(1) 결제완료</MenuItem>
              <MenuItem value={10}>(2) 접수대기</MenuItem>
              <MenuItem value={20}>(3-1) 정상접수</MenuItem>
              <MenuItem value={30}>(3-2) 지연접수</MenuItem>
              <MenuItem value={40}>(4) 배달지연</MenuItem>
              <MenuItem value={50}>(5) 픽업완료</MenuItem>
              <MenuItem value={60}>(6) 배달완료</MenuItem>
              <MenuItem value={0}>===========</MenuItem>
              <MenuItem value={-1}>(-1) 환불완료</MenuItem>
              <MenuItem value={0}>===========</MenuItem>
              <MenuItem value={100}>(공구)접수대기</MenuItem>
              <MenuItem value={110}>(공구)배송시작</MenuItem>
              <MenuItem value={120}>(공구)배송완료</MenuItem>
              <MenuItem value={-2}>(공구)환불완료</MenuItem>
            </Select>
          </FormControl>
        ),
        poidCycle: prevPoid !== item.poid && (
          <FormControl sx={{m: 1, minWidth: 98, maxWidth: 98, padding: 0, margin: 0}} size="small">
            <Select
              labelId="demo-select-small"
              value={item.poid_state}
              sx={{
                fontSize: '18px',
                fontWeight: '600',
                backgroundColor: Utils.getSelectBoxColor(item.poid_state),
              }}
              onChange={e => {
                const state = e.target.value;
                if (state < 0) {
                  Utils.showDialog('알림', '전체 환불 처리 진행하시겠습니까? (POID)').then(result => {
                    result && handlePoidChange(item, idx, state);
                  });
                } else {
                  handlePoidChange(item, idx, state);
                }
              }}>
              <MenuItem value={1}>(1) 결제완료</MenuItem>
              <MenuItem value={10}>(2) 접수대기</MenuItem>
              <MenuItem value={20}>(3-1) 정상접수</MenuItem>
              <MenuItem value={30}>(3-2) 지연접수</MenuItem>
              <MenuItem value={40}>(4) 배달지연</MenuItem>
              <MenuItem value={50}>(5) 픽업완료</MenuItem>
              <MenuItem value={60}>(6) 배달완료</MenuItem>
              <MenuItem value={0}>===========</MenuItem>
              <MenuItem value={-1}>(-1) 환불완료</MenuItem>
              <MenuItem value={0}>===========</MenuItem>
              <MenuItem value={100}>(공구)접수대기</MenuItem>
              <MenuItem value={110}>(공구)배송시작</MenuItem>
              <MenuItem value={120}>(공구)배송완료</MenuItem>
              <MenuItem value={-2}>(공구)환불완료</MenuItem>
            </Select>
          </FormControl>
        ),
      });

      // 이전 POID 기억
      if (prevPoid !== item.poid) prevPoid = item.poid;

      // 이전 SOID 기억
      if (prevSoid !== item.soid) prevSoid = item.soid;
    });
    setLoding(false);
    setRows(renderItem);

    // 엑셀 다운로드
    setRoomExcelData(dispatch, excelItem);
  };

  return (
    <DashboardLayout style={{overflowY: 'hidden'}}>
      <HeaderNavbar title="룸서비스" mode="room" />

      <MDBox pt={2}>
        {loading && (
          <div className="spinner-view">
            <GridLoader color={'#222'} loading={true} size={8} />
          </div>
        )}

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card className="full-height">
              {tableData.length > 0 ? (
                <MDBox style={{overflowY: 'scroll', height: '100vh', width: '100%', margin: '4px'}}>
                  <MDBox className="thover">
                    <DataTable table={{columns, rows}} isSorted={false} entriesPerPage={false} showTotalEntries={false} noEndBorder />
                  </MDBox>
                  <br />
                  <br />
                </MDBox>
              ) : (
                <MDBox className="empty-nodata">
                  {!loading && (
                    <MDBox className="flex-center">
                      <Icon fontSize="medium" style={{marginRight: '4px'}}>
                        not_interested
                      </Icon>
                      <MDTypography variant="h6" fontWeight="medium">
                        데이터가 없습니다.
                      </MDTypography>
                    </MDBox>
                  )}
                </MDBox>
              )}
            </Card>
          </Grid>
          {renderSnack}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default RoomService;
