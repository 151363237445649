import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import DashboardLayout from '../../../modules/LayoutContainers/DashboardLayout';
import HeaderNavbar from '../../../modules/Navbars/HeaderNavbar';
import * as Utils from '../../../shared/utils';
import '../../../assets/common.css';
import ComplexStatisticsCard from '../../../modules/Cards/StatisticsCards/ComplexStatisticsCard';
import * as Service from '../../../shared/apiService';
import {APP_KEY_LOADING_ROOM_SERVICE, ROOM_SERVICE_UNDER_STATE_1_COMPLETE_REFUND} from '../../../shared/Constants';

// Chart
import ReportsLineChart from '../../../modules/Charts/LineCharts/ReportsLineChart';
import ReportsBarChart from '../../../modules/Charts/BarCharts/ReportsBarChart';

function Main() {
  const navigate = useNavigate();
  const [workingDayCount, setWorkingDayCount] = useState(0);
  const [deliveryTotalCount, setDeliveryTotalCount] = useState(0);
  const [deliveryMonthData, setDeliveryMonthData] = useState([]);
  const [deliveryDayData, setDeliveryDayData] = useState([]);
  const [thisMonthIdx, setThisMonthIdx] = useState(null);
  const [thisMonth, setThisMonth] = useState(0);
  const [deliveryCountThisMonth, setDeliveryCountThisMonth] = useState(0);
  const [deliveryCountLastMonth, setDeliveryCountLastMonth] = useState(0);
  const [memberStore, setMemberStore] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [addrSettingCount, setAddrSettingCount] = useState(0);
  const [thisMonthCustomer, setThisMonthCustomer] = useState(0);
  const [lastMonthCustomer, setLastMonthCustomer] = useState(0);
  const [orderData, setOrderData] = useState([]);
  const [thisMonthGMV, setThisMonthGMV] = useState(0);
  const [totalMonthGMV, setTotalMonthGMV] = useState(0);

  const [monthStaticsGMV, setMonthStaticsGMV] = useState([]);
  const [dayStaticsGMV, setDayStaticsGMV] = useState([]);
  const [dayStaticsGMVlabel, setDayStaticsGMVlabel] = useState([]);

  //labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  const chartMonthData = {
    labels: ['4월', '5월', '6월', '7월', '8월', '9월'],
    datasets: {label: '월 매출', data: monthStaticsGMV},
  };

  const chartDayData = {
    labels: dayStaticsGMVlabel,
    datasets: {label: '일 매출', data: dayStaticsGMV},
  };

  // -- useEffect -----------------------------------------------------------------------
  useEffect(() => {
    // - 로그인 체크
    Utils.chkLogin().then(result => {
      !result && navigate('/');
    });

    // - 전체 주문 리스트
    Service.getOrderTotalList().then(result => {
      setOrderData(result);
    });

    // - 전체 배달 완료 건수
    Service.getDeliveryDoneCount().then(result => {
      setDeliveryTotalCount(result.count);
    });

    // - 월별 배달 완료 건수
    Service.getDeliveryDoneMonthCount().then(result => {
      setDeliveryMonthData(result);
    });

    // - 일별 배달 완료 건수
    Service.getDeliveryDoneDayCount().then(result => {
      setDeliveryDayData(result);
    });

    // - 스토어 리스트
    Service.getRoomStoreListByZoneCode('4_DT2_G1').then(result => {
      const parseResult = result.filter(item => item.is_members === 'Y');
      setMemberStore(parseResult);
    });

    // - 가입자 리스트
    Service.getAllUserList().then(result => {
      setCustomerData(result);
    });

    localStorage.setItem(APP_KEY_LOADING_ROOM_SERVICE, false);
  }, []);

  useEffect(() => {
    // 이번달 배달 건수
    const thisMonth = new Date().getMonth() + 1;
    setThisMonth(thisMonth);

    deliveryMonthData.forEach((item, idx) => {
      if (item.mon === thisMonth) {
        setThisMonthIdx(idx);
      }
    });
  }, [deliveryMonthData]);

  useEffect(() => {
    if (deliveryDayData.length > 0) {
      setWorkingDayCount(deliveryDayData.length);
    }
  }, [deliveryDayData]);

  useEffect(() => {
    if (thisMonthIdx) {
      // 이번달 배달건수
      setDeliveryCountThisMonth(deliveryMonthData[thisMonthIdx].count);
      // 지난달 배달건수
      setDeliveryCountLastMonth(deliveryMonthData[thisMonthIdx - 1].count);
    }
  }, [thisMonthIdx]);

  useEffect(() => {
    if (customerData.length > 0) {
      const result = customerData.filter(item => item.room_id && item.room_id.length > 2);
      setAddrSettingCount(result.length);

      // 월별 회원 가입자수 계산
      // TODO: 1년이 넘어갈때 처리를 해줘야 함. (12월->1월)
      const thisMonth = new Date().getMonth();
      const lastMonth = new Date().getMonth() - 1;
      const thisMonData = customerData.filter(item => new Date(item.create_date).getMonth() === thisMonth);
      const lastMonData = customerData.filter(item => new Date(item.create_date).getMonth() === lastMonth);
      setThisMonthCustomer(thisMonData.length);
      setLastMonthCustomer(lastMonData.length);
    }
  }, [customerData]);

  useEffect(() => {
    if (orderData.length > 0) {
      calcMonthGMV();
    }
  }, [orderData]);

  /*  useEffect(() => {
    if (orderData.length > 0) {
    }
  }, [orderData]);*/

  // -- feature -------------------------------------------------------------------------------
  const calcMonthGMV = () => {
    const thisMonth = new Date().getMonth();
    let prevPoid = '';
    let sumGmv = 0;
    let refundPrice = 0;
    let thisSumGmv = 0;
    let thisRefundPrice = 0;

    // 월별 통계 해시맵
    let monthMap = new Map();
    for (let i = 0; i < 12; i++) {
      monthMap.set(i, 0);
    }

    // 1주일 데이 통계 해시맵
    let weekDayMap = new Map();
    for (let i = 0; i <= 7; i++) {
      let today = new Date();
      today.setDate(today.getDate() - i);

      let month = today.getMonth() + 1;
      let day = today.getDate();
      let rDay = month + '/' + day;
      weekDayMap.set(rDay, 0);
    }

    orderData.forEach((item, idx) => {
      const itemMonth = new Date(item.create_date).getMonth();
      const itemDay = Utils.getChartDateDayType(item.create_date);

      // - 매출 구하기
      if (prevPoid !== item.poid) {
        prevPoid = item.poid;
        // 매출 더하기
        if (item.poid_state > 0) {
          sumGmv += item.pay_price;
          if (thisMonth === itemMonth) {
            thisSumGmv += item.pay_price;
          }

          // 월별 통계
          let temp = monthMap.get(itemMonth) + item.pay_price;
          monthMap.set(itemMonth, temp);

          // 일별 통계
          if (weekDayMap.has(itemDay)) {
            let temp = weekDayMap.get(itemDay) + item.pay_price;
            weekDayMap.set(itemDay, temp);
          }
        }
      }

      // - 부분 환불 구하기
      const finalPrice = item.boid_price ? item.boid_price : item.price;
      if (item.poid_state > 0 && item.boid_state < 0) {
        refundPrice += finalPrice * item.count;
        if (thisMonth === itemMonth) {
          thisRefundPrice += finalPrice * item.count;
        }

        // 월별 통계
        let temp = monthMap.get(itemMonth) - finalPrice * item.count;
        monthMap.set(itemMonth, temp);

        // 일별 통계
        if (weekDayMap.has(itemDay)) {
          let temp = weekDayMap.get(itemDay) - finalPrice * item.count;
          weekDayMap.set(itemDay, temp);
        }
      }
    });

    console.log('[전체 GMV] : ' + Utils.wonPriceStr(sumGmv));
    console.log('[전체 환불 합계] : ' + Utils.wonPriceStr(refundPrice));

    console.log('[이번달 GMV] : ' + Utils.wonPriceStr(thisSumGmv));
    console.log('[이번달 환불 합계] : ' + Utils.wonPriceStr(thisRefundPrice));
    setTotalMonthGMV(sumGmv - refundPrice);
    setThisMonthGMV(thisSumGmv - thisRefundPrice);

    console.log('[월별 통계]');
    console.log(monthMap);

    console.log('[일별 통계]');
    console.log(weekDayMap);

    // 월별 데이터 처리
    const tempArray = [];
    for (let i = 3; i <= thisMonth; i++) {
      tempArray.push(monthMap.get(i));
    }
    console.log(tempArray);
    setMonthStaticsGMV(tempArray);

    // 월별 데이터 처리
    const dayDataArr = [];
    const dayLabelArr = [];
    let week = new Array('일', '월', '화', '수', '목', '금', '토');
    let year = new Date().getFullYear();

    for (let [key, value] of weekDayMap) {
      let parse = key.split('/');
      let month = parse[0] - 1;
      let day = parse[1];
      let mDay = week[new Date(year, month, day).getDay()];

      dayLabelArr.push(key + ' ' + mDay);
      dayDataArr.push(value);
    }
    setDayStaticsGMV(dayDataArr.reverse());
    setDayStaticsGMVlabel(dayLabelArr.reverse());
  };

  // -- handler -------------------------------------------------------------------------------

  // -- render -------------------------------------------------------------------------------

  return (
    <DashboardLayout>
      <HeaderNavbar title="대시보드" />

      <MDBox py={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={2}>
            <Link to="/room">
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="restaurant"
                  title={thisMonth + '월 GMV'}
                  count={Utils.wonPriceStr(thisMonthGMV) + ' 원'}
                  percentage={{
                    color: 'success',
                    amount: Utils.wonPriceStr(totalMonthGMV) + '원',
                    label: '전체 GMV',
                  }}
                />
              </MDBox>
            </Link>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Link to="/room">
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="restaurant"
                  title={thisMonth + '월 배달완료'}
                  count={deliveryCountThisMonth + ' 건'}
                  percentage={{
                    color: 'success',
                    amount: deliveryTotalCount + '건',
                    label: '전체 배달완료',
                  }}
                />
              </MDBox>
            </Link>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Link to="/customer">
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person"
                  title={thisMonth + '월 가입자'}
                  count={thisMonthCustomer + ' 명'}
                  percentage={{
                    color: 'success',
                    amount: lastMonthCustomer + '명',
                    label: '지난달 가입자수',
                  }}
                />
              </MDBox>
            </Link>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Link to="/customer">
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person"
                  title="주소 설정 (중복 포함)"
                  count={addrSettingCount + ' 세대'}
                  percentage={{
                    color: 'success',
                    amount: customerData.length + ' 명',
                    label: '전체 가입자',
                  }}
                />
              </MDBox>
            </Link>
          </Grid>

          <Grid item xs={12} md={6} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon="directions_run"
                title="룸서비스 워킹데이"
                count={workingDayCount + ' 일'}
                percentage={{
                  color: 'success',
                  label: '정식 오픈일(03/24) / 주문 1건 이상',
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Link to="/store">
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title="룸서비스 가맹점"
                  count={memberStore.length}
                  percentage={{
                    color: 'success',
                    label: '동탄2신도시 1구역',
                  }}
                />
              </MDBox>
            </Link>
          </Grid>
        </Grid>

        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart color="info" title="월 GMV" description="월별 매출 현황" date="Latest update" chart={chartMonthData} />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart color="dark" title="일 GMV" description="일별 매출 현황" date="Latest update" chart={chartDayData} />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>

      {/*<MDBox pt={2}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card className="full-height">
              <MDBox className="empty-nodata">
                <MDBox className="flex-center">
                  <Icon fontSize="medium" style={{marginRight: '4px'}}>
                    science
                  </Icon>
                  <MDTypography variant="h6" fontWeight="medium">
                    개발 진행중
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>*/}
    </DashboardLayout>
  );
}

export default Main;
